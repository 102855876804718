var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var fla,gla,hla,ila,jla,kla,lla;$CLJS.CV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);fla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);gla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);
$CLJS.DV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.EV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.FV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
hla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);ila=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);jla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
kla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.GV=new $CLJS.N("type","temporal-bucketing-option","type/temporal-bucketing-option",1870188464);$CLJS.HV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.IV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.JV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);lla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var KV=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.mk,$CLJS.vl,$CLJS.ul,$CLJS.Uj,$CLJS.bm,$CLJS.gk,$CLJS.Jj],null),LV=$CLJS.si(KV),mla,ola,UV;$CLJS.jL(jla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid date extraction unit"],null)],null),LV));var MV=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xz,$CLJS.Yk,$CLJS.jl,$CLJS.Tj,$CLJS.Jj],null),NV=$CLJS.si(MV);
$CLJS.jL(ila,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid date truncation unit"],null)],null),NV));$CLJS.OV=$CLJS.oh.j($CLJS.Lg,$CLJS.jn.A(),$CLJS.qg.h(MV,KV));mla=$CLJS.si($CLJS.OV);$CLJS.jL($CLJS.FV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid date bucketing unit"],null)],null),mla));var PV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rk,$CLJS.bk],null),QV=$CLJS.si(PV);
$CLJS.jL(hla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid time extraction unit"],null)],null),QV));var RV=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.uz,$CLJS.tz,$CLJS.vz,$CLJS.wz],null),SV=$CLJS.si(RV);$CLJS.jL(lla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid time truncation unit"],null)],null),SV));
$CLJS.nla=$CLJS.oh.j($CLJS.Lg,$CLJS.Mm.h($CLJS.Pm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.tz,null,$CLJS.uz,null],null),null)),$CLJS.jn.A()),$CLJS.qg.h(RV,PV));ola=$CLJS.si(PV);$CLJS.jL(gla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid time bucketing unit"],null)],null),ola));$CLJS.TV=$CLJS.oh.j($CLJS.Lg,$CLJS.Mm.h($CLJS.Pm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.tz,null,$CLJS.uz,null],null),null)),$CLJS.jn.A()),$CLJS.qg.l(RV,MV,$CLJS.G([PV,KV])));
UV=$CLJS.si($CLJS.TV);$CLJS.jL($CLJS.CV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid datetime bucketing unit"],null)],null),UV));var pla=$CLJS.kf.h(UV,$CLJS.Pj);$CLJS.jL($CLJS.IV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid temporal bucketing unit"],null)],null),pla));var qla=$CLJS.yz.h(NV,SV);
$CLJS.jL($CLJS.DV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid datetime truncation unit"],null)],null),qla));$CLJS.VV=$CLJS.yz.h(LV,QV);$CLJS.jL(fla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid datetime extraction unit"],null)],null),$CLJS.VV));var WV=$CLJS.kf.h(NV,$CLJS.Jj);$CLJS.jL($CLJS.JV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid date interval unit"],null)],null),WV));
$CLJS.jL($CLJS.EV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid time interval unit"],null)],null),SV));var rla=$CLJS.yz.h(WV,SV);$CLJS.jL($CLJS.HV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ll,"Valid datetime interval unit"],null)],null),rla));
$CLJS.jL(kla,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.GV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,$CLJS.IV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Pj,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null)],null));