var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var Uma;$CLJS.iY=new $CLJS.N("mbql.filter","operator","mbql.filter/operator",-1267834132);Uma=new $CLJS.N("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);for(var jY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk],null)),kY=null,lY=0,mY=0;;)if(mY<lY){var Vma=kY.$(null,mY);$CLJS.yV(Vma,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)],null)],null)]));mY+=1}else{var nY=$CLJS.A(jY);if(nY){var oY=nY;if($CLJS.re(oY)){var pY=$CLJS.$c(oY),Wma=$CLJS.ad(oY),
Xma=pY,Yma=$CLJS.E(pY);jY=Wma;kY=Xma;lY=Yma}else{var Zma=$CLJS.C(oY);$CLJS.yV(Zma,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)],null)],null)]));jY=$CLJS.D(oY);kY=null;lY=0}mY=0}else break}$CLJS.wV($CLJS.Tk,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)]));
for(var qY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.CK],null)),rY=null,sY=0,tY=0;;)if(tY<sY){var $ma=rY.$(null,tY);$CLJS.yV($ma,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.ML],null)],null)],null)],null)]));tY+=1}else{var uY=$CLJS.A(qY);if(uY){var vY=uY;if($CLJS.re(vY)){var wY=$CLJS.$c(vY),ana=$CLJS.ad(vY),
bna=wY,cna=$CLJS.E(wY);qY=ana;rY=bna;sY=cna}else{var dna=$CLJS.C(vY);$CLJS.yV(dna,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.ML],null)],null)],null)],null)]));qY=$CLJS.D(vY);rY=null;sY=0}tY=0}else break}
for(var xY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.im,$CLJS.um,$CLJS.km,$CLJS.tm],null)),yY=null,zY=0,AY=0;;)if(AY<zY){var ena=yY.$(null,AY);$CLJS.wV(ena,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null)]));AY+=1}else{var BY=$CLJS.A(xY);if(BY){var CY=BY;if($CLJS.re(CY)){var DY=$CLJS.$c(CY),fna=$CLJS.ad(CY),gna=DY,hna=$CLJS.E(DY);xY=fna;yY=gna;zY=hna}else{var ina=$CLJS.C(CY);$CLJS.wV(ina,$CLJS.G([$CLJS.zu,$CLJS.Bl,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null)]));xY=$CLJS.D(CY);yY=null;zY=0}AY=0}else break}$CLJS.wV($CLJS.JI,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null)]));
$CLJS.wV($CLJS.UI,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.OL],null)]));
for(var EY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xI,$CLJS.nJ],null)),FY=null,GY=0,HY=0;;)if(HY<GY){var jna=FY.$(null,HY);$CLJS.wV(jna,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.JL],null)]));HY+=1}else{var IY=$CLJS.A(EY);if(IY){var JY=IY;if($CLJS.re(JY)){var KY=$CLJS.$c(JY),kna=$CLJS.ad(JY),lna=KY,mna=$CLJS.E(KY);EY=kna;FY=lna;GY=mna}else{var nna=$CLJS.C(JY);$CLJS.wV(nna,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.JL],null)]));EY=$CLJS.D(JY);
FY=null;GY=0}HY=0}else break}
for(var LY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KI,$CLJS.$I],null)),MY=null,NY=0,OY=0;;)if(OY<NY){var ona=MY.$(null,OY);$CLJS.wV(ona,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.PL],null)]));OY+=1}else{var PY=$CLJS.A(LY);if(PY){var QY=PY;if($CLJS.re(QY)){var RY=$CLJS.$c(QY),pna=$CLJS.ad(QY),qna=RY,rna=$CLJS.E(RY);LY=pna;MY=qna;NY=rna}else{var sna=$CLJS.C(QY);$CLJS.wV(sna,$CLJS.G([$CLJS.zu,$CLJS.Bl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.PL],null)]));LY=$CLJS.D(QY);
MY=null;NY=0}OY=0}else break}
for(var SY=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$J,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null)],null),TY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.uJ,$CLJS.mK,$CLJS.KJ,$CLJS.vJ],null)),UY=null,VY=0,WY=0;;)if(WY<VY){var XY=UY.$(null,WY);$CLJS.tV(XY,$CLJS.Bl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Nt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,XY],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iu,$CLJS.wL,SY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.PL],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.PL],null)],null));WY+=1}else{var YY=$CLJS.A(TY);if(YY){var ZY=YY;if($CLJS.re(ZY)){var $Y=$CLJS.$c(ZY),tna=$CLJS.ad(ZY),una=$Y,vna=$CLJS.E($Y);TY=tna;UY=una;VY=vna}else{var aZ=$CLJS.C(ZY);$CLJS.tV(aZ,$CLJS.Bl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Nt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,aZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iu,$CLJS.wL,SY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.PL],
null)],null));TY=$CLJS.D(ZY);UY=null;VY=0}WY=0}else break}
$CLJS.tV($CLJS.qK,$CLJS.Bl,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.Nt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.qK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iu,$CLJS.wL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xK,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.HL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.It,$CLJS.nB,$CLJS.pD,$CLJS.RI],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.iq,$CLJS.RL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.HV],null)],null));$CLJS.tV($CLJS.nI,$CLJS.Bl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Nt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.nI],null),$CLJS.wL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.qL,$CLJS.sL],null)],null));
$CLJS.jL(Uma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.iY],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AV,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.It,$CLJS.hm,$CLJS.CK,$CLJS.UI,$CLJS.JI,$CLJS.im,$CLJS.km,$CLJS.um,$CLJS.tm,$CLJS.xI,$CLJS.nJ,$CLJS.KI,$CLJS.$I,$CLJS.KJ,$CLJS.vJ,$CLJS.uJ,$CLJS.mK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.TI,$CLJS.Pl],null)],null));