var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Hoa,b0,Ioa,Joa,Loa,Koa;$CLJS.Y_=function(a,b){return $CLJS.R.j($CLJS.xa,a,b)};Hoa=function(a){var b=new $CLJS.rb,c=$CLJS.Sz;$CLJS.Sz=new $CLJS.gd(b);try{var d=$CLJS.Sz,e=$CLJS.Gb($CLJS.$A(d)),f=$CLJS.Sz;$CLJS.Sz=e?$CLJS.RA(d):d;try{d=$CLJS.bB;$CLJS.bB=!0;try{$CLJS.dB(a)}finally{$CLJS.bB=d}$CLJS.F.h(0,$CLJS.Zz($CLJS.Sz,$CLJS.bA))||$CLJS.Qc($CLJS.Sz,"\n");$CLJS.Yz()}finally{$CLJS.Sz=f}$CLJS.Oz($CLJS.p.g(b))}finally{$CLJS.Sz=c}};
$CLJS.Z_=function(a){var b=new $CLJS.rb,c=$CLJS.Ab;$CLJS.Ab=function(d){return b.append(d)};try{Hoa(a)}finally{$CLJS.Ab=c}return $CLJS.p.g(b)};$CLJS.$_=function(a){return $CLJS.qe(a)&&1<$CLJS.E(a)&&$CLJS.C(a)instanceof $CLJS.N&&$CLJS.oe($CLJS.Zd(a))&&$CLJS.Ie($CLJS.Zd(a),$CLJS.IG)};$CLJS.a0=function(a){return $CLJS.$_(a)?$CLJS.Lu(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.LL],null)):null};
b0=function(a,b,c){var d=$CLJS.Ie(a,b)?$CLJS.Rm.j(a,b,function(e){return $CLJS.qe(e)&&$CLJS.F.h($CLJS.C(e),$CLJS.Lj)?$CLJS.Mg($CLJS.kg(1,e)):new $CLJS.S(null,1,5,$CLJS.T,[e],null)}):a;return $CLJS.Ie(a,b)?$CLJS.PN(d,$CLJS.Pf([b,c])):d};Ioa=function(a){var b=$CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.YP,$CLJS.kR],null));b=c0.g?c0.g(b):c0.call(null,b);return $CLJS.fW($CLJS.U.l(b0($CLJS.Em.l(a,$CLJS.YP,$CLJS.G([$CLJS.kR])),$CLJS.$R,$CLJS.kZ),$CLJS.sl,$CLJS.cZ,$CLJS.G([$CLJS.hZ,b])))};
Joa=function(a){return $CLJS.Qm.h(Ioa,a)};Loa=function(a){return $CLJS.m(a)?$CLJS.U.j($CLJS.Rm.j($CLJS.xe(a)?new $CLJS.k(null,1,[$CLJS.d0,a],null):a,$CLJS.d0,function(b){return $CLJS.Qm.h(function(c){return $CLJS.U.j($CLJS.cG(c,$CLJS.oG),$CLJS.sl,$CLJS.hW)},b)}),$CLJS.sl,Koa):null};
$CLJS.e0=function(a){if($CLJS.F.h($CLJS.sl.g(a),$CLJS.MZ))return a;var b=$CLJS.Wk.g(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return $CLJS.gn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.sl,$CLJS.MZ,$CLJS.hZ,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.gn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.sl,$CLJS.IZ],null),$CLJS.PN($CLJS.TQ.g(a),new $CLJS.k(null,1,[$CLJS.uO,$CLJS.TQ],null))]))],null)],null),$CLJS.Em.l(a,$CLJS.Wk,$CLJS.G([$CLJS.TQ]))]));case "query":return $CLJS.gn.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.sl,$CLJS.MZ,$CLJS.hZ,c0($CLJS.uO.g(a))],null),$CLJS.Em.l(a,$CLJS.Wk,$CLJS.G([$CLJS.uO]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.f0=function(a){return"string"===typeof a&&(a=$CLJS.Ei(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.J(a,0,null),a=$CLJS.J(a,1,null),$CLJS.oL(a)):null};$CLJS.g0=new $CLJS.N("lib","source","lib/source",-434086550);$CLJS.h0=new $CLJS.N("metadata","segment","metadata/segment",-1286738015);
$CLJS.i0=new $CLJS.N("metadata","table","metadata/table",-882499405);$CLJS.Moa=new $CLJS.N(null,"num-stages","num-stages",1426797535);$CLJS.j0=new $CLJS.N(null,"tables","tables",1334623052);$CLJS.k0=new $CLJS.N(null,"table-id","table-id",-766649466);$CLJS.d0=new $CLJS.N(null,"columns","columns",1998437288);$CLJS.Noa=new $CLJS.N("metadata","database","metadata/database",-924828824);$CLJS.l0=new $CLJS.N(null,"table","table",-564943036);$CLJS.m0=new $CLJS.N("metadata","card","metadata/card",-1039333889);
Koa=new $CLJS.N("metadata","results","metadata/results",330329298);var c0;
c0=function c0(a){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.kR),d=$CLJS.M.h(a,$CLJS.NP);c=$CLJS.m(c)?c0.g?c0.g(c):c0.call(null,c):$CLJS.Lg;d=Loa(d);var e=$CLJS.E(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.Ru(c,new $CLJS.S(null,2,5,$CLJS.T,[e,$CLJS.oZ],null),d):c;d=$CLJS.m($CLJS.TQ.g(a))?$CLJS.IZ:$CLJS.CZ;a=$CLJS.gn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.sl,d],null),$CLJS.Em.l(a,$CLJS.kR,$CLJS.G([$CLJS.NP]))]));a=$CLJS.A($CLJS.UO.g(a))?$CLJS.Rm.j(a,$CLJS.UO,Joa):a;a=b0(a,$CLJS.IO,$CLJS.NZ);return $CLJS.kf.h(c,a)};
$CLJS.n0=RegExp(" id$","i");