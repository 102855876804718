var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.test.check.random.js");require("./clojure.test.check.rose_tree.js");
'use strict';var Zw,$w,ax,bx,cx,dx,ex,Yba,fx,gx,Zba,$ba,ix,kx,mx,aca,ox,px,qx,bca,tx,cca,dca,ux,wx,xx,Ax,eca,Bx,fca,Fx,Gx,gca,hca,Lx,Nx,lca,nca,Vx,Xx,Yx,Zx,qca,hx,yx,rx,jx,cy,sx,Cx,rca;$CLJS.Yw=function(a){return a-1};Zw=function(a){if(null!=a&&null!=a.Se)a=a.Se(a);else{var b=Zw[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Zw._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IRandom.rand-long",a);}return a};
$w=function(a){if(null!=a&&null!=a.jg)a=a.jg(a);else{var b=$w[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=$w._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IRandom.rand-double",a);}return a};ax=function(a,b){if(null!=a&&null!=a.kg)a=a.kg(a,b);else{var c=ax[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=ax._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("IRandom.split-n",a);}return a};bx=function(a){return a.root};
cx=function(a,b){this.root=a;this.children=b;this.C=16;this.K=0};dx=function(a,b){return new cx(a,b)};ex=function(a,b,c,d,e){this.M=a;this.f=b;this.Dg=c;this.seed=d;this.next=e;this.C=26083532;this.K=1};Yba=function(a,b){return new ex(null,a,null,b,null)};
fx=function(a,b,c){return $CLJS.Jd(b)?new $CLJS.gf(null,$CLJS.r(b),null,1,null):$CLJS.nf(b,new $CLJS.yf(null,function(){var d=$CLJS.A(c);if(d){var e=fx;var f=$CLJS.C(d);f=a.h?a.h(b,f):a.call(null,b,f);d=e(a,f,$CLJS.zd(d))}else d=null;return d},null,null))};
gx=function(a){return function d(c){return new $CLJS.yf(null,function(){for(var e=c;;){var f=$CLJS.A(e);if(f){var g=f,l=$CLJS.C(g),n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);if(f=$CLJS.A(function(u,v,x,y,B,H){return function Y(Q){return new $CLJS.yf(null,function(aa,ha,qa,Ea){return function(){for(;;){var kb=$CLJS.A(Q);if(kb){if($CLJS.re(kb)){var lb=$CLJS.$c(kb),Fb=$CLJS.E(lb),Mb=$CLJS.Bf(Fb);a:for(var ac=0;;)if(ac<Fb){var Tb=$CLJS.be(lb,ac);Tb=$CLJS.U.j(a,Ea,Tb);Mb.add(Tb);ac+=1}else{lb=!0;break a}return lb?
$CLJS.Ef($CLJS.Gf(Mb),Y($CLJS.ad(kb))):$CLJS.Ef($CLJS.Gf(Mb),null)}Mb=$CLJS.C(kb);return $CLJS.nf($CLJS.U.j(a,Ea,Mb),Y($CLJS.zd(kb)))}return null}}}(u,v,x,y,B,H),null,null)}}(e,l,n,q,g,f)(n.children)))return $CLJS.qg.h(f,d($CLJS.zd(e)));e=$CLJS.zd(e)}else return null}},null,null)}($CLJS.rg.j($CLJS.Vm,a,$CLJS.Ai(0,Number.MAX_VALUE)))};Zba=function(a){return $CLJS.qg.h($CLJS.dg(function(b){return hx(b,a)},a),gx($CLJS.Mg(a)))};
$ba=function(a,b){if(4<=$CLJS.E(b)){var c=$CLJS.af($CLJS.E(b),2);return new $CLJS.yf(null,function(){return $CLJS.nf(function(){var d=$CLJS.Wm.j(b,0,c);return ix.h?ix.h(a,d):ix.call(null,a,d)}(),new $CLJS.yf(null,function(){var d=$CLJS.Wm.h(b,c);d=ix.h?ix.h(a,d):ix.call(null,a,d);return new $CLJS.gf(null,d,null,1,null)},null,null))},null,null)}return null};ix=function(a,b){var c=jx(a,b);return dx(c.root,$CLJS.qg.h($ba(a,b),c.children))};
kx=function(a,b){var c=ix(a,b);a=dx(a.A?a.A():a.call(null),$CLJS.Lg);return $CLJS.je(b)?c:dx(c.root,$CLJS.nf(a,c.children))};$CLJS.lx=function(a,b,c,d){this.sd=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};mx=function(a){return new $CLJS.lx(a,null,null,null)};$CLJS.nx=function(a,b,c){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.yl);return a.h?a.h(b,c):a.call(null,b,c)};aca=function(a){return mx(function(){return a})};
ox=function(a,b){b=$CLJS.Qf(b);var c=$CLJS.M.h(b,$CLJS.yl);return mx(function(d,e){d=c.h?c.h(d,e):c.call(null,d,e);return a.g?a.g(d):a.call(null,d)})};px=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.yl);return mx(function(d,e){d=$CLJS.Pw(d);var f=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);f=c.h?c.h(f,e):c.call(null,f,e);f=b.g?b.g(f):b.call(null,f);f=$CLJS.Qf(f);f=$CLJS.M.h(f,$CLJS.yl);return f.h?f.h(d,e):f.call(null,d,e)})};
qx=function(a){return mx(function(b,c){return $CLJS.Qm.j(function(d,e){return $CLJS.nx(d,e,c)},a,ax(b,$CLJS.E(a)))})};$CLJS.ow=function(a,b){return ox(function(c){return rx(a,c)},b)};$CLJS.sw=function(a){return aca(dx(a,$CLJS.Lg))};bca=function(a){return function(b){return ox(sx,mx(function(c,d){return rx(function(e){return $CLJS.nx(a.g?a.g(e):a.call(null,e),c,d)},b)}))}};tx=function(a,b){return px(a,bca(b))};
cca=function(a){return $CLJS.vi(function(b){return $CLJS.ki.h(0,b)},Yba(function(b){return $CLJS.af(b,2)},a))};dca=function(a){return $CLJS.rg.h(function(b){return a-b},cca(a))};ux=function(a,b,c){a=$w(a);return $CLJS.$e(Math.floor(b+(a*(1+c)-a*b)))};$CLJS.vx=function(a){return mx(function(b,c){var d=a.g?a.g(c):a.call(null,c);return $CLJS.nx(d,b,c)})};wx=function(a,b){b=$CLJS.Qf(b);var c=$CLJS.M.h(b,$CLJS.yl);return mx(function(d){return c.h?c.h(d,a):c.call(null,d,a)})};
xx=function(a,b){return $CLJS.vx(function(c){return wx(a.g?a.g(c):a.call(null,c),b)})};Ax=function(a,b){return mx(function(c){c=ux(c,a,b);return yx(function(d){return d>=a&&d<=b},zx(c))})};$CLJS.qw=function(a){return tx(Ax(0,$CLJS.E(a)-1),function(b){return $CLJS.Td(a,b)})};eca=function(a,b){return $CLJS.E($CLJS.vi(function(c){return c<=b},$CLJS.zd(fx($CLJS.Gm,0,a))))};
Bx=function(a){var b=$CLJS.ug($CLJS.Mm.h($CLJS.cf,$CLJS.C),a),c=$CLJS.R.h($CLJS.Gm,$CLJS.rg.h($CLJS.C,b));return mx(function(d,e){return $CLJS.nx(px(Ax(0,c-1),function(f){var g=eca($CLJS.rg.h($CLJS.C,b),f.root);return ox(function(l){return dx(l.root,new $CLJS.yf(null,function(){return $CLJS.qg.h(function(){return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);a:for(var H=0;;)if(H<y){var I=$CLJS.be(x,H);
I=$CLJS.nx($CLJS.Zd($CLJS.Td(b,I)),d,e);B.add(I);H+=1}else{x=!0;break a}return x?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}B=$CLJS.C(v);return $CLJS.nf($CLJS.nx($CLJS.Zd($CLJS.Td(b,B)),d,e),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Ai(0,g))}(),l.children)},null,null))},$CLJS.Zd($CLJS.Td(b,g)))}),d,e)})};$CLJS.pw=function(a){var b=$CLJS.Mg(a);return ox(function(c){return rx(b,c)},Ax(0,$CLJS.E(b)-1))};
fca=function(a,b,c,d,e){c=$CLJS.Qf(c);var f=$CLJS.M.h(c,Cx),g=$CLJS.M.h(c,$CLJS.Dx);for(c=g;;){if(0===c)throw function(){var q=new $CLJS.k(null,3,[$CLJS.zl,a,$CLJS.yl,b,$CLJS.Dx,g],null);return f.g?f.g(q):f.call(null,q)}();var l=$CLJS.Pw(d);d=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=$CLJS.nx(b,d,e);if($CLJS.m(function(){var q=n.root;return a.g?a.g(q):a.call(null,q)}()))return yx(a,n);d=l;e+=1;--c}};
$CLJS.kw=function(a){return px($CLJS.vx(function(b){return Ax(0,b)}),function(b){return ox(function(c){return kx($CLJS.X,c)},qx($CLJS.ng(b.root,a)))})};
Fx=function(a,b,c,d,e,f,g,l,n,q){var u=$CLJS.Tc($CLJS.Lg),v=$CLJS.Tc($CLJS.oi),x=e;e=f;for(var y=0;;){if($CLJS.F.h(n,y)&&$CLJS.E(u)<l)throw function(){var I=new $CLJS.k(null,3,[$CLJS.yl,d,$CLJS.Dx,n,$CLJS.Ex,g],null);return q.g?q.g(I):q.call(null,I)}();if($CLJS.F.h(n,y)||$CLJS.F.h($CLJS.E(u),g))return kx(function(){return function(){function I(Y){var aa=null;if(0<arguments.length){aa=0;for(var ha=Array(arguments.length-0);aa<ha.length;)ha[aa]=arguments[aa+0],++aa;aa=new $CLJS.z(ha,0,null)}return Q.call(this,
aa)}function Q(Y){return $CLJS.oh.h(a,Y)}I.v=0;I.B=function(Y){Y=$CLJS.A(Y);return Q(Y)};I.l=Q;return I}()}(u,v,x,e,y),function(){var I=x,Q=$CLJS.Vc(u);return c.h?c.h(I,Q):c.call(null,I,Q)}());f=$CLJS.Pw(x);var B=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);B=$CLJS.nx(d,B,e);var H=B.root;H=b.g?b.g(H):b.call(null,H);null!=$CLJS.xm(v,H)?(e+=1,y+=1,x=f):(y=$CLJS.Uc(u,B),v=$CLJS.Uc(v,H),u=y,x=f,y=0)}};Gx=function(a,b){var c=$CLJS.je(b);return c?c:$CLJS.R.h($CLJS.Su,$CLJS.rg.h(a,b))};
gca=function(a,b){var c=$CLJS.ae(b),d=$CLJS.Mg(b);b=$CLJS.E(b);var e=b-1;return $CLJS.oh.h(c,$CLJS.C($CLJS.Sb(function(f,g){var l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);f=$CLJS.Pw(f);var n=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);var q=ux(n,g,e);n=$CLJS.T;q=new $CLJS.S(null,2,5,$CLJS.T,[g,q],null);g=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l=$CLJS.U.l(l,q,l.g?l.g(g):l.call(null,g),$CLJS.G([g,l.g?l.g(q):l.call(null,q)]));return new $CLJS.S(null,2,5,n,[l,f],null)},new $CLJS.S(null,2,5,$CLJS.T,[d,a],null),
$CLJS.Ai(0,b))))};
$CLJS.Jx=function(a,b,c,d,e,f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.Ex),l=$CLJS.M.h(f,$CLJS.Hx),n=$CLJS.M.h(f,$CLJS.Ix),q=$CLJS.M.j(f,$CLJS.Dx,10),u=$CLJS.M.j(f,Cx,function(H){return $CLJS.zj("Couldn't generate enough distinct elements!",H)}),v=$CLJS.m(d)?gca:function(H,I){return I},x=$CLJS.m(g)?g:$CLJS.m(l)?l:1;if($CLJS.m(g)){var y=function(H){return $CLJS.F.h(g,$CLJS.E(H))};return mx(function(H,I){return yx($CLJS.m(c)?$CLJS.Nm.h(y,function(Q){return Gx(b,Q)}):y,Fx(a,b,v,e,H,I,g,x,q,u))})}var B=
$CLJS.m(l)?l:0;y=$CLJS.m(n)?function(H){return B<=$CLJS.E(H)&&$CLJS.E(H)<=n}:function(H){return B<=$CLJS.E(H)};return px($CLJS.m(n)?Ax(B,n):$CLJS.vx(function(H){return Ax(B,B+H)}),function(H){var I=H.root;return mx(function(Q,Y){return yx($CLJS.m(c)?$CLJS.Nm.h(y,function(aa){return Gx(b,aa)}):y,Fx(a,b,v,e,Q,Y,I,x,q,u))})})};
hca=function(a,b,c,d){for(var e=function(){var g=$CLJS.ka($CLJS.ra(b,64-a));return 0===c?Math.abs(g):g}();;){if(c<=e&&e<=d)return e;var f=-e;if(c<=f&&f<=d)return f;e=function(){var g=e;if(!$CLJS.Zf(e)){var l=0>e?$CLJS.Fu:$CLJS.Yw;return l.g?l.g(g):l.call(null,g)}return g}()/2}};Lx=function(a,b){return $CLJS.vx(function(c){c=1<c?c:1;return ox(function(d){var e=d.root;d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return zx(hca(d,e,a,b))},$CLJS.Kx.l($CLJS.G([Ax(1,54>c?c:54),ica])))})};
$CLJS.tw=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.qk);b=$CLJS.M.h(b,$CLJS.Zl);var c=$CLJS.m(a)?a:jca,d=$CLJS.m(b)?b:Mx;return $CLJS.rw.h(function(e){return c<=e&&e<=d},0>=c&&0<=d?Lx(c,d):0>d?$CLJS.ow(function(e){return d+e},Lx(c-d,0)):$CLJS.ow(function(e){return c+e},Lx(0,d-c)))};Nx=function(a){if(0===a)return-1023;var b=Math.abs(a);a=Math.floor(Math.log(b)*Math.LOG2E);b*=Math.pow(2,-a);return 1>b?a-1:2<=b?a+1:a};
lca=function(a,b){function c(l,n){return $CLJS.vx(function(q){var u=1<<$CLJS.af(200<q?200:q,8);return 0>=l&&0<=n?Ax(function(){var v=-u;return l>v?l:v}(),n<u?n:u):0>n?Ax(function(){var v=n-u;return l>v?l:v}(),n):Ax(l,function(){var v=l+u;return n<v?n:v}())})}if(null==a&&null==b)return $CLJS.Kx.l($CLJS.G([c(-1023,1023),$CLJS.pw(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))]));var d=$CLJS.m(a)?a:kca,e=$CLJS.m(b)?b:Ox,f=function(){var l=Nx(d);return-1023>l?-1023:l}(),g=function(){var l=Nx(e);return-1023>
l?-1023:l}();return 0<=d?$CLJS.Kx.l($CLJS.G([c(f,g),$CLJS.sw(1)])):0>=e?$CLJS.Kx.l($CLJS.G([c(g,f),$CLJS.sw(-1)])):$CLJS.ow(function(l){var n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);return 0>q&&f<n||0<q&&g<n?new $CLJS.S(null,2,5,$CLJS.T,[n,-q],null):l},$CLJS.Kx.l($CLJS.G([c(-1023,g>f?g:f),$CLJS.pw(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))])))};
nca=function(a,b){var c=$CLJS.m(a)?$CLJS.m(b)?function(e){return a<=e&&e<=b}:function(e){return a<=e}:$CLJS.m(b)?function(e){return e<=b}:null,d=$CLJS.ow(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=$CLJS.J(e,1,null)/Math.pow(2,52)+1;var l=e*Math.pow(2,g)*f;var n=(n=null==c)?n:c.g?c.g(l):c.call(null,l);if($CLJS.m(n))return l;f=Px(g,f);g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=$CLJS.m(a)?g>a?g:a:g;f=$CLJS.m(b)?f<b?f:b:f;e=g+(f-g)*(e-1);e=e<f?e:f;return e>g?e:g},$CLJS.Kx.l($CLJS.G([lca(a,
b),mca])));return $CLJS.m(c)?$CLJS.rw.h(c,d):d};
$CLJS.Ux=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.j(b,$CLJS.Qx,!0);a=$CLJS.M.j(b,$CLJS.Rx,!0);var d=$CLJS.M.h(b,$CLJS.qk);b=$CLJS.M.h(b,$CLJS.Zl);var e=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[95,nca(d,b)],null)],null);e=(null==d?null==b||0<=b:null==b?0>=d:0>=d&&0<=b)?$CLJS.kf.l(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.sw(0)],null),$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.sw(-0)],null)])):e;b=$CLJS.m($CLJS.m(c)?null==b:c)?$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.sw(Sx)],
null)):e;c=$CLJS.m($CLJS.m(c)?null==d:c)?$CLJS.kf.h(b,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.sw(Tx)],null)):b;a=$CLJS.m(a)?$CLJS.kf.h(c,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.sw(oca)],null)):c;return $CLJS.F.h(1,$CLJS.E(a))?$CLJS.Zd($CLJS.C(a)):Bx(a)};Vx=function(a){return xx(function(b){return $CLJS.$e(Math.pow(b,.6))},a)};Xx=function(a,b,c){return $CLJS.qw(new $CLJS.S(null,12,5,$CLJS.T,[Wx,$CLJS.Aw,a,b,c,pca,$CLJS.vw,$CLJS.yw,$CLJS.zw,$CLJS.Dw,$CLJS.Ew,$CLJS.Fw],null))};
Yx=function(a){if("number"===typeof a){var b=isNaN(a);a=$CLJS.m(b)?b:$CLJS.F.h(Tx,a)||$CLJS.F.h(Sx,a);a=$CLJS.Gb(a)}else a=!0;return a};Zx=function(a){return $CLJS.qw(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.nw.g(a),$CLJS.kw(a),$CLJS.mw.g($CLJS.rw.h(Yx,a)),xx(function(b){return $CLJS.af(b,2)},$CLJS.lw.h($CLJS.rw.h(Yx,a),a))],null))};qca=function(a){return mx(function(b,c){var d=$CLJS.Pw(b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.nx(a.g?a.g(b):a.call(null,b),d,c)})};
$CLJS.ay=function(a,b){return $CLJS.vx(function(c){return tx(Ax(0,$CLJS.$e(Math.pow(c,1.1))),function(d){return qca(function(e){e=$x(d,e);var f=wx(c,b);return $CLJS.Sb(function(g,l){return tx(Ax(0,10),function(n){return 0===n?f:wx(l,a.g?a.g(g):a.call(null,g))})},f,e)})})})};
$CLJS.Tw.prototype.kg=$CLJS.Ma(53,function(a,b){switch(b){case 0:return $CLJS.Lg;case 1:return new $CLJS.S(null,1,5,$CLJS.T,[this],null);default:a=b-1;var c=this.state;for(b=$CLJS.Tc($CLJS.Lg);;){if($CLJS.F.h(a,$CLJS.E(b)))return $CLJS.Vc($CLJS.If(b,new $CLJS.Tw(this.gamma,c)));var d=this.gamma.add(c);c=this.gamma.add(d);var e=$CLJS.Sw(c);d=new $CLJS.Tw(e,$CLJS.Rw(d));b=$CLJS.Uc(b,d)}}});
$CLJS.Tw.prototype.jg=$CLJS.Ma(52,function(){var a=this.Se(null);a=$CLJS.Lw(a,11);return $CLJS.Iw*(a.Ba>>>0)+$CLJS.Qba*a.ra});$CLJS.Tw.prototype.Se=$CLJS.Ma(51,function(){return $CLJS.Rw(this.state.add(this.gamma))});var by={};cx.prototype.$=function(a,b){if($CLJS.F.h(b,0))return this.root;if($CLJS.F.h(b,1))return this.children;throw Error("Index out of bounds in rose tree");};cx.prototype.Ma=function(a,b,c){return $CLJS.F.h(b,0)?this.root:$CLJS.F.h(b,1)?this.children:c};$CLJS.h=ex.prototype;
$CLJS.h.toString=function(){return $CLJS.hd(this)};$CLJS.h.N=function(){return this.M};$CLJS.h.Ja=function(){return this.Pa(null)};$CLJS.h.wa=function(){return $CLJS.yd};$CLJS.h.ad=function(){return this.seed!==by};$CLJS.h.Na=function(a,b){a=this.Ia(null);var c=this.f.g?this.f.g(a):this.f.call(null,a);for(a=b.h?b.h(a,c):b.call(null,a,c);;){if($CLJS.Jd(a))return $CLJS.r(a);c=this.f.g?this.f.g(c):this.f.call(null,c);a=b.h?b.h(a,c):b.call(null,a,c)}};
$CLJS.h.Oa=function(a,b,c){a=this.Ia(null);for(c=b.h?b.h(c,a):b.call(null,c,a);;){if($CLJS.Jd(c))return $CLJS.r(c);a=this.f.g?this.f.g(a):this.f.call(null,a);c=b.h?b.h(c,a):b.call(null,c,a)}};$CLJS.h.Ia=function(){by===this.seed&&(this.seed=this.f.g?this.f.g(this.Dg):this.f.call(null,this.Dg));return this.seed};$CLJS.h.Pa=function(){null==this.next&&(this.next=new ex(null,this.f,this.Ia(null),by,null));return this.next};$CLJS.h.ba=function(){return this};
$CLJS.h.O=function(a,b){return b===this.M?this:new ex(b,this.f,this.Dg,this.seed,this.next)};$CLJS.h.ga=function(a,b){return $CLJS.nf(b,this)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,$CLJS.Ni,"("," ",")",c,this)};hx=function hx(a,b){return new $CLJS.yf(null,function(){var d=$CLJS.A(b);if(d)if(0===a)var e=$CLJS.zd(b);else{e=$CLJS.C(d);var f=a-1;d=$CLJS.zd(d);f=hx.h?hx.h(f,d):hx.call(null,f,d);e=$CLJS.nf(e,f)}else e=null;return e},null,null)};
yx=function yx(a,b){return dx(b.root,$CLJS.rg.h(function(d){return yx.h?yx.h(a,d):yx.call(null,a,d)},$CLJS.ug(function(d){d=d.root;return a.g?a.g(d):a.call(null,d)},b.children)))};rx=function rx(a,b){return dx(function(){var d=b.root;return a.g?a.g(d):a.call(null,d)}(),$CLJS.rg.h(function(d){return rx.h?rx.h(a,d):rx.call(null,a,d)},b.children))};
jx=function jx(a,b){return $CLJS.A(b)?dx($CLJS.R.h(a,$CLJS.rg.h(bx,b)),$CLJS.rg.h(function(d){return jx.h?jx.h(a,d):jx.call(null,a,d)},Zba($CLJS.jg(Infinity,b)))):dx(a.A?a.A():a.call(null),$CLJS.Lg)};cy=function cy(a,b){return dx($CLJS.R.h(a,$CLJS.rg.h(bx,b)),$CLJS.rg.h(function(d){return cy.h?cy.h(a,d):cy.call(null,a,d)},gx(b)))};sx=function sx(a){var c=a.root,d=c.children;return dx(c.root,$CLJS.qg.h($CLJS.rg.h(sx,a.children),d))};$CLJS.Hx=new $CLJS.N(null,"min-elements","min-elements",949370780);
Cx=new $CLJS.N(null,"ex-fn","ex-fn",-284925510);rca=new $CLJS.N(null,"max-tries-or-opts","max-tries-or-opts",-609860571);$CLJS.Rx=new $CLJS.N(null,"NaN?","NaN?",-1917767651);$CLJS.Ix=new $CLJS.N(null,"max-elements","max-elements",433034073);$CLJS.Ex=new $CLJS.N(null,"num-elements","num-elements",1960422107);$CLJS.Dx=new $CLJS.N(null,"max-tries","max-tries",-1824441792);$CLJS.Qx=new $CLJS.N(null,"infinite?","infinite?",-2017886608);var zx,sca,Wx,tca,ica,Mx,jca,Sx,Tx,Ox,kca,oca,ey,mca,Px,fy,uca,hy,vca,wca,iy,jy,ky,pca,xca,ly,$x;$CLJS.jw={};$CLJS.h=$CLJS.lx.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "gen":return this.sd;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#clojure.test.check.generators.Generator{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yl,this.sd],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.yl],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=236843149^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.sd,b.sd)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.yl,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.lx(this.sd,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "gen":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.yl,b):$CLJS.O.call(null,$CLJS.yl,b))?new $CLJS.lx(c,this.F,this.m,null):new $CLJS.lx(this.sd,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.yl,this.sd)],null),this.m))};
$CLJS.h.O=function(a,b){return new $CLJS.lx(this.sd,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};zx=function zx(a){return dx(a,$CLJS.rg.h(zx,dca(a)))};sca=new $CLJS.k(null,2,[Cx,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.Dx);return $CLJS.zj(["Couldn't satisfy such-that predicate after ",$CLJS.p.g(b)," tries."].join(""),a)},$CLJS.Dx,10],null);
$CLJS.rw=function rw(a){switch(arguments.length){case 2:return rw.h(arguments[0],arguments[1]);case 3:return rw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.rw.h=function(a,b){return $CLJS.rw.j(a,b,10)};
$CLJS.rw.j=function(a,b,c){if($CLJS.Ae(c))c=new $CLJS.k(null,1,[$CLJS.Dx,c],null);else if(!$CLJS.oe(c))throw $CLJS.zj("Bad argument to such-that!",new $CLJS.k(null,1,[rca,c],null));var d=$CLJS.gn.l($CLJS.G([sca,c]));return mx(function(e,f){return fca(a,b,d,e,f)})};$CLJS.rw.v=3;$CLJS.vw=$CLJS.pw(new $CLJS.S(null,2,5,$CLJS.T,[!1,!0],null));$CLJS.Kx=function Kx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Kx.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
$CLJS.Kx.l=function(a){return ox(function(b){return cy($CLJS.Vm,b)},qx(a))};$CLJS.Kx.v=0;$CLJS.Kx.B=function(a){return this.l($CLJS.A(a))};$CLJS.dy=$CLJS.vx(function(a){return Ax(0,a)});Wx=$CLJS.vx(function(a){return Ax(-a,a)});tca=$CLJS.ow(function(a){return-1*a},$CLJS.dy);$CLJS.ow($CLJS.Fu,$CLJS.dy);$CLJS.ow($CLJS.Yw,tca);
$CLJS.nw=function nw(a){switch(arguments.length){case 1:return nw.g(arguments[0]);case 2:return nw.h(arguments[0],arguments[1]);case 3:return nw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.nw.g=function(a){return px($CLJS.vx(function(b){return Ax(0,b)}),function(b){return ox(function(c){return kx($CLJS.Vm,c)},qx($CLJS.ng(b.root,a)))})};$CLJS.nw.h=function(a,b){return $CLJS.R.h($CLJS.Kx,$CLJS.ng(b,a))};
$CLJS.nw.j=function(a,b,c){return px(Ax(b,c),function(d){return ox(function(e){return yx(function(f){return $CLJS.E(f)>=b&&$CLJS.E(f)<=c},kx($CLJS.Vm,e))},qx($CLJS.ng(d.root,a)))})};$CLJS.nw.v=3;$CLJS.mw=function mw(a){switch(arguments.length){case 1:return mw.g(arguments[0]);case 2:return mw.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.mw.g=function(a){return $CLJS.mw.h(a,$CLJS.P)};
$CLJS.mw.h=function(a,b){return $CLJS.Jx($CLJS.oi,$CLJS.Ye,!1,!1,a,b)};$CLJS.mw.v=2;$CLJS.lw=function lw(a){switch(arguments.length){case 2:return lw.h(arguments[0],arguments[1]);case 3:return lw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.lw.h=function(a,b){return $CLJS.lw.j(a,b,$CLJS.P)};$CLJS.lw.j=function(a,b,c){return $CLJS.Jx($CLJS.P,$CLJS.C,!1,!1,$CLJS.Kx.l($CLJS.G([a,b])),c)};$CLJS.lw.v=3;
ica=mx(function(a){return dx(Zw(a),$CLJS.Lg)});Mx=$CLJS.R.h($CLJS.Hm,$CLJS.ng(53,2))-1;jca=-Mx;$CLJS.Aw=$CLJS.tw($CLJS.P);Sx=Number.POSITIVE_INFINITY;Tx=Number.NEGATIVE_INFINITY;Ox=Number.MAX_VALUE;kca=-Ox;oca=Number.NaN;
ey=function ey(a){return 32>=a?Ax(0,function(){switch($CLJS.$e(a)){case 32:return 4294967295;case 31:return 2147483647;default:return(1<<a)-1}}()):$CLJS.ow(function(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return 4294967296*d+c},$CLJS.Kx.l($CLJS.G([function(){var c=a-32;return ey.g?ey.g(c):ey.call(null,c)}(),ey.g?ey.g(32):ey.call(null,32)])))};
mca=$CLJS.ow(function(a){for(var b=0,c=Math.pow(2,52);;){if(1>a)return b*c;var d=a/2;c/=2;b=2*b+(a&1);a=d}},$CLJS.vx(function(a){return px(Ax(0,52>a?a:52),function(b){return ey(b.root)})}));
Px=function Px(a,b){return 0>b?(b=-b,b=Px.h?Px.h(a,b):Px.call(null,a,b),a=$CLJS.J(b,0,null),b=$CLJS.J(b,1,null),new $CLJS.S(null,2,5,$CLJS.T,[-b,-a],null)):$CLJS.F.h(-1023,a)?new $CLJS.S(null,2,5,$CLJS.T,[0,(1*Math.pow(2,52)-1)*Math.pow(2,-1074)],null):new $CLJS.S(null,2,5,$CLJS.T,[1*Math.pow(2,a),(1*Math.pow(2,52)-1)*Math.pow(2,a-51)],null)};$CLJS.xw=$CLJS.Ux($CLJS.P);$CLJS.ww=$CLJS.ow($CLJS.Gu,Ax(0,255));fy=$CLJS.ow($CLJS.Gu,Ax(32,126));
$CLJS.gy=$CLJS.ow($CLJS.Gu,$CLJS.qw(new $CLJS.S(null,3,5,$CLJS.T,[Ax(48,57),Ax(65,90),Ax(97,122)],null)));uca=$CLJS.ow($CLJS.Gu,$CLJS.qw(new $CLJS.S(null,2,5,$CLJS.T,[Ax(65,90),Ax(97,122)],null)));hy=$CLJS.pw(new $CLJS.S(null,7,5,$CLJS.T,"*+!-_?.".split(""),null));vca=Bx(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[14,$CLJS.gy],null),new $CLJS.S(null,2,5,$CLJS.T,[7,hy],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.sw(":")],null)],null));
wca=Bx(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[2,uca],null),new $CLJS.S(null,2,5,$CLJS.T,[1,hy],null)],null));iy=$CLJS.ow($CLJS.dv,$CLJS.nw.g($CLJS.ww));jy=$CLJS.ow($CLJS.dv,$CLJS.nw.g(fy));$CLJS.Cw=$CLJS.ow($CLJS.dv,$CLJS.nw.g($CLJS.gy));
ky=$CLJS.ow(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);b=$CLJS.dv.g($CLJS.nf(b,a));return $CLJS.Pu($CLJS.Pu(b,/:{2,}/,":"),/:$/,"")},$CLJS.rw.h(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);a=$CLJS.J(a,0,null);b=$CLJS.m(a)?(b="+"===b||"-"===b)?!/[^0-9]/.test(a):b:a;return!$CLJS.ye(b)},$CLJS.Kx.l($CLJS.G([wca,$CLJS.nw.g(vca)]))));$CLJS.yw=Vx($CLJS.ow($CLJS.Oi,ky));
$CLJS.zw=Vx($CLJS.ow(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Oi.h(b,a)},$CLJS.Kx.l($CLJS.G([ky,ky]))));$CLJS.Dw=Bx(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[100,Vx($CLJS.ow($CLJS.Ui,ky))],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.sw($CLJS.Wu)],null)],null));$CLJS.Ew=Vx($CLJS.ow(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ui.h(b,a)},$CLJS.Kx.l($CLJS.G([ky,ky]))));
pca=$CLJS.ow(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return b/a},$CLJS.Kx.l($CLJS.G([Wx,$CLJS.ow($CLJS.Fu,$CLJS.dy)])));
$CLJS.Fw=function(a){return ox(function(b){return dx(b.root,$CLJS.Lg)},a)}($CLJS.ow(function(a){function b(d){return(a.g?a.g(d):a.call(null,d)).toString(16)}var c=(((a.g?a.g(15):a.call(null,15))&3)+8).toString(16);return $CLJS.Ju([$CLJS.p.g(b(0)),$CLJS.p.g(b(1)),$CLJS.p.g(b(2)),$CLJS.p.g(b(3)),$CLJS.p.g(b(4)),$CLJS.p.g(b(5)),$CLJS.p.g(b(6)),$CLJS.p.g(b(7)),"-",$CLJS.p.g(b(8)),$CLJS.p.g(b(9)),$CLJS.p.g(b(10)),$CLJS.p.g(b(11)),"-4",$CLJS.p.g(b(12)),$CLJS.p.g(b(13)),$CLJS.p.g(b(14)),"-",$CLJS.p.g(c),
$CLJS.p.g(b(16)),$CLJS.p.g(b(17)),$CLJS.p.g(b(18)),"-",$CLJS.p.g(b(19)),$CLJS.p.g(b(20)),$CLJS.p.g(b(21)),$CLJS.p.g(b(22)),$CLJS.p.g(b(23)),$CLJS.p.g(b(24)),$CLJS.p.g(b(25)),$CLJS.p.g(b(26)),$CLJS.p.g(b(27)),$CLJS.p.g(b(28)),$CLJS.p.g(b(29)),$CLJS.p.g(b(30))].join(""))},$CLJS.nw.h(Ax(0,15),31)));xca=Xx($CLJS.xw,$CLJS.ww,iy);$CLJS.Bw=Xx($CLJS.xw,fy,jy);Xx($CLJS.Ux(new $CLJS.k(null,1,[$CLJS.Rx,!1],null)),$CLJS.ww,iy);Xx($CLJS.Ux(new $CLJS.k(null,1,[$CLJS.Rx,!1],null)),fy,jy);ly=Math.log(2);
$x=function $x(a,b){if(2>=a)return new $CLJS.S(null,1,5,$CLJS.T,[a],null);var d=Math.log(a);b=$CLJS.Pw(b);var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);d=$CLJS.$e(Math.exp($w(e)*(d-ly)+ly));e=$CLJS.af(a,d);return 1<d&&1<e?$CLJS.nf(d,$x.h?$x.h(e,b):$x.call(null,e,b)):new $CLJS.S(null,1,5,$CLJS.T,[a],null)};$CLJS.yca=$CLJS.ay(Zx,xca);$CLJS.uw=$CLJS.ay(Zx,$CLJS.Bw);