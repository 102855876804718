var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Jz,Lz,Mz,Nz,Pz,Tz,Uz,Vz,Wz,Xz,Yca,aA,Zca,dA,eA,fA,gA,hA,iA,jA,kA,pA,sA,uA,xA,$ca,ada,CA,bda,EA,FA,GA,MA,NA,VA,ZA,eB,gB,lB,pB,uB,wB,xB,rB,zB,AB,CB,BB,DB,fda,IB,RB,SB,LB,hda,WB,ZB,aC,bC,jda,kda,lda,fC,jC,kC,lC,mC,mda,qC,tC,nda,oda,pda,qda,rda,sda,tda,uda,vda,xda,zC,wda,DC,yda,EC,zda,Ada,FC,Bda,GC,Cda,Dda,Eda,Gda,Hda,Jda,Kda,Mda,Oda,LC,OC,Qda,Pda,Rda,qB,tB,VC,WC,XC,YC,aD,bD,Uda,Vda,Wda,eD,Xda,fD,dD,nA,hD,BA,sC,Yda,iB,Zda,$da,qA,zA,ZC,aea,XA,HB,HA,bea,cea,UA,dea,MC,LA,GB,SA,vC,eea,AA,
fea,CC,JC,iD,gea,cC,kD,eC,WA,FB,mA,IC,eda,hB,QA,tA,hea,yB,iea,OB,dC,QC,jea,SC,dda,kea,lea,mea,mB,nea,AC,kB,oea,PB,NB,pea,vA,qea,Tda,TC,IA,mD,hC,nD,oD,TB,rea,KA,sea,Sda,tea,oB,yC,EB,uea,iC,$C,vea,xC,wea,MB,yA,xea,YA,qD,gC,yea,rA,HC,jB,cda,KC,wA,nC,NC,rC,oC,pC,BC,UB,rD,sD,zea,Aea,cA,lA,Bea,JA,Cea;Jz=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.Kz=function(){};
Lz=function(a){if(null!=a&&null!=a.Sc)a=a.Sc(a);else{var b=Lz[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Lz._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IWriter.-flush",a);}return a};Mz=function(){};Nz=function(a,b){return a-b*$CLJS.af(a,b)};$CLJS.Oz=function(a){if(null==$CLJS.Ab)throw Error("No *print-fn* fn set for evaluation environment");$CLJS.Ab.call(null,a);return null};
Pz=function(a,b,c){if(null!=a&&null!=a.o)a.o(a,b,c);else{var d=Pz[$CLJS.Na(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Pz._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Pb("IMultiFn.-add-method",a);}};$CLJS.Qz=function(a,b){var c=$CLJS.Tc($CLJS.P);a=$CLJS.A(a);for(b=$CLJS.A(b);;)if(a&&b)c=$CLJS.rh.j(c,$CLJS.C(a),$CLJS.C(b)),a=$CLJS.D(a),b=$CLJS.D(b);else return $CLJS.Vc(c)};
Tz=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.R.h(Rz,0<b.length?new $CLJS.z(b.slice(0),0,null):null);$CLJS.Qc($CLJS.Sz,"\n")};Uz=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Vz=function(a,b,c){var d=c;for(c=$CLJS.Lg;;){if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null);var e=$CLJS.C(d);d=$CLJS.D(d);e=$CLJS.R.h(a,new $CLJS.S(null,2,5,$CLJS.T,[e,b],null));b=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);c=$CLJS.kf.h(c,b);b=e}};Wz=function(a,b){var c=b;for(b=$CLJS.Lg;;){var d=$CLJS.R.h(a,new $CLJS.S(null,1,5,$CLJS.T,[c],null));c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if($CLJS.Gb(c))return new $CLJS.S(null,2,5,$CLJS.T,[b,d],null);b=$CLJS.kf.h(b,c);c=d}};
Xz=function(a){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);var v=$CLJS.J(q,0,null);$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[u,v],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=$CLJS.J(f,0,null);$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,g],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}()),$CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,
2,5,$CLJS.T,[u,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())],null)};
Yca=function(a,b){return $CLJS.oh.h($CLJS.P,function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);n.add(new $CLJS.S(null,2,5,$CLJS.T,[v,new $CLJS.S(null,2,5,$CLJS.T,[u,b],null)],null));q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);
g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[n,new $CLJS.S(null,2,5,$CLJS.T,[g,b],null)],null),e($CLJS.zd(f)))}return null}},null,null)}(a)}())};$CLJS.Yz=function(){var a=$CLJS.Sz;if(null!=a&&null!=a.ig)a.ig(a);else{var b=$CLJS.Yz[$CLJS.Na(null==a?null:a)];if(null!=b)b.g?b.g(a):b.call(null,a);else if(b=$CLJS.Yz._,null!=b)b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IPrettyFlush.-ppflush",a);}};$CLJS.Zz=function(a,b){a=$CLJS.r($CLJS.r(a));return b.g?b.g(a):b.call(null,a)};
aA=function(a,b,c){$CLJS.dj.D($CLJS.r(a),$CLJS.U,b,c)};Zca=function(a,b){$CLJS.F.h(b,"\n")?(aA(a,$CLJS.bA,0),aA(a,$CLJS.Xk,$CLJS.Zz(a,$CLJS.Xk)+1)):aA(a,$CLJS.bA,$CLJS.Zz(a,$CLJS.bA)+1);return $CLJS.Qc($CLJS.Zz(a,cA),b)};dA=function(a,b,c,d){this.La=a;this.Bg=b;this.Hd=c;this.Vi=d;this.C=1074167808;this.K=0};
eA=function(a,b,c,d,e,f,g,l,n,q,u,v,x){this.parent=a;this.Mb=b;this.Eb=c;this.Bb=d;this.Gb=e;this.Hb=f;this.prefix=g;this.Lb=l;this.Nb=n;this.Ib=q;this.F=u;this.m=v;this.G=x;this.C=2230716170;this.K=139264};fA=function(a,b){for(b=$CLJS.cv.g(b);;){if(null==b)return!1;if(a===b)return!0;b=$CLJS.cv.g(b)}};gA=function(a,b,c,d,e,f,g,l){this.ja=a;this.data=b;this.wc=c;this.ia=d;this.ha=e;this.F=f;this.m=g;this.G=l;this.C=2230716170;this.K=139264};
hA=function(a,b,c,d,e,f,g,l){this.ja=a;this.type=b;this.la=c;this.ia=d;this.ha=e;this.F=f;this.m=g;this.G=l;this.C=2230716170;this.K=139264};iA=function(a,b,c,d,e,f,g){this.ja=a;this.la=b;this.ia=c;this.ha=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};jA=function(a,b,c,d,e,f,g){this.ja=a;this.la=b;this.ia=c;this.ha=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};
kA=function(a,b,c,d,e,f,g,l,n){this.ja=a;this.la=b;this.lc=c;this.offset=d;this.ia=e;this.ha=f;this.F=g;this.m=l;this.G=n;this.C=2230716170;this.K=139264};
pA=function(a,b,c){b=$CLJS.A(b);for(var d=null,e=0,f=0;;)if(f<e){var g=d.$(null,f);if(!$CLJS.F.h(lA.g(g),mA)){var l=nA.g($CLJS.r($CLJS.r(a)));$CLJS.m(l)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),l)}oA.h(a,g);$CLJS.dj.D($CLJS.r(a),$CLJS.U,nA,nA.g(g));g=nA.g($CLJS.r($CLJS.r(a)));$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),g),$CLJS.dj.D($CLJS.r(a),$CLJS.U,nA,null));f+=1}else if(b=$CLJS.A(b))$CLJS.re(b)?(d=$CLJS.$c(b),b=$CLJS.ad(b),g=d,e=$CLJS.E(d),d=g):(g=$CLJS.C(b),$CLJS.F.h(lA.g(g),mA)||
(d=nA.g($CLJS.r($CLJS.r(a))),$CLJS.m(d)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),d)),oA.h(a,g),$CLJS.dj.D($CLJS.r(a),$CLJS.U,nA,nA.g(g)),g=nA.g($CLJS.r($CLJS.r(a))),$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),g),$CLJS.dj.D($CLJS.r(a),$CLJS.U,nA,null)),b=$CLJS.D(b),d=null,e=0),f=0;else break};sA=function(a,b){var c=$CLJS.Zz(cA.g($CLJS.r($CLJS.r(a))),$CLJS.Zl),d;(d=null==c)||(a=$CLJS.Zz(cA.g($CLJS.r($CLJS.r(a))),$CLJS.bA),b=(b=$CLJS.A(b))?qA.g($CLJS.$d(b))-rA.g($CLJS.C(b)):0,d=a+b<c);return d};
uA=function(a,b,c){b=$CLJS.r(tA.g(b));return $CLJS.m(b)?b:!sA(a,c)};xA=function(a,b,c){var d=vA.g($CLJS.r($CLJS.r(a))),e=$CLJS.Zz(cA.g($CLJS.r($CLJS.r(a))),$CLJS.Zl);return $CLJS.m(d)?$CLJS.m(e)?(d=$CLJS.r(wA.g(b))>=e-d)?uA(a,b,c):d:e:d};$ca=function(a){var b=$CLJS.C(a),c=yA.g(b);b=$CLJS.A($CLJS.vi(function(d){return!($CLJS.F.h(lA.g(d),mA)&&fA(yA.g(d),c))},$CLJS.D(a)));return new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.A($CLJS.kg($CLJS.E(b)+1,a))],null)};
ada=function(a){var b=$CLJS.C(a),c=yA.g(b);return $CLJS.A($CLJS.vi(function(d){var e=yA.g(d);return!($CLJS.F.h(lA.g(d),mA)&&($CLJS.F.h(e,c)||fA(e,c)))},$CLJS.D(a)))};
CA=function(a,b){$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.dj.D($CLJS.r(a),$CLJS.U,nA,null);b=yA.g(b);var c=zA.g(b);$CLJS.m(c)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),c);c=$CLJS.R.h($CLJS.p,$CLJS.ng($CLJS.r(AA.g(b))-$CLJS.E(c)," "));$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),c);a:for($CLJS.gg(BA.g(b),!0),$CLJS.gg(tA.g(b),!0),a=$CLJS.cv.g(b);;)if($CLJS.m(a))$CLJS.gg(tA.g(a),!0),$CLJS.gg(BA.g(a),!0),a=$CLJS.cv.g(a);else break a;return null};
bda=function(a){var b=$CLJS.A($CLJS.vi(function(c){return!$CLJS.F.h(lA.g(c),mA)},a));return new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.A($CLJS.kg($CLJS.E(b),a))],null)};EA=function(a){for(var b=$CLJS.Uu.g($CLJS.r($CLJS.r(a)));;){$CLJS.dj.D($CLJS.r(a),$CLJS.U,$CLJS.Uu,$CLJS.oh.h($CLJS.Lg,b));if(sA(a,b))return null;var c=DA(a,b);if(b!==c)b=c;else return null}};
FA=function(a,b){$CLJS.dj.D($CLJS.r(a),$CLJS.U,$CLJS.Uu,$CLJS.kf.h($CLJS.Uu.g($CLJS.r($CLJS.r(a))),b));return sA(a,$CLJS.Uu.g($CLJS.r($CLJS.r(a))))?null:EA(a)};GA=function(a){var b=nA.g($CLJS.r($CLJS.r(a)));return $CLJS.m(b)?($CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),b),$CLJS.dj.D($CLJS.r(a),$CLJS.U,nA,null)):null};
MA=function(a,b){var c=$CLJS.Qu(b,"\n",-1);if($CLJS.F.h($CLJS.E(c),1))return b;b=zA.g($CLJS.C(HA.g($CLJS.r($CLJS.r(a)))));var d=$CLJS.C(c);if($CLJS.F.h(IA,$CLJS.dk.g($CLJS.r($CLJS.r(a))))){var e=JA.g($CLJS.r($CLJS.r(a))),f=e+$CLJS.E(d);$CLJS.dj.D($CLJS.r(a),$CLJS.U,JA,f);FA(a,new gA(KA,d,null,e,f,null,null,null));EA(a);d=$CLJS.Uu.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(pA(a,d,!0),$CLJS.dj.D($CLJS.r(a),$CLJS.U,$CLJS.Uu,$CLJS.Lg))}else GA(a),$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),d);$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),
"\n");d=$CLJS.A($CLJS.D($CLJS.ti(c)));e=null;for(var g=f=0;;)if(g<f){var l=e.$(null,g);$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),l);$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.m(b)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),b);g+=1}else if(d=$CLJS.A(d))e=d,$CLJS.re(e)?(d=$CLJS.$c(e),g=$CLJS.ad(e),e=d,f=$CLJS.E(d),d=g):(d=$CLJS.C(e),$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),d),$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),"\n"),$CLJS.m(b)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),b),d=$CLJS.D(e),e=null,f=0),g=0;else break;$CLJS.dj.D($CLJS.r(a),
$CLJS.U,IA,LA);return $CLJS.$d(c)};NA=function(a,b,c,d,e,f){this.La=a;this.Bg=b;this.fj=c;this.ei=d;this.Hd=e;this.Wi=f;this.C=1074167808;this.K=0};
$CLJS.RA=function(a){var b=$CLJS.OA,c=PA,d=new eA(null,null,$CLJS.cj.g(0),$CLJS.cj.g(0),$CLJS.cj.g(!1),$CLJS.cj.g(!1),null,null,null,null,null,null,null),e=$CLJS.cj.g,f=$CLJS.cj.g(new $CLJS.k(null,4,[$CLJS.Zl,b,$CLJS.bA,0,$CLJS.Xk,0,cA,a],null));e=e.call($CLJS.cj,$CLJS.Sh([HA,vA,cda,QA,dda,$CLJS.dk,JA,nA,cA,eda,$CLJS.Uu],[d,c,d,!0,null,LA,0,null,new dA(a,b,f,$CLJS.P),1,$CLJS.Lg]));return new NA(a,b,c,d,e,$CLJS.P)};
VA=function(a,b){var c=$CLJS.Sz;b=new eA(HA.g($CLJS.r($CLJS.r(c))),null,$CLJS.cj.g(0),$CLJS.cj.g(0),$CLJS.cj.g(!1),$CLJS.cj.g(!1),a,null,b,null,null,null,null);$CLJS.dj.D($CLJS.r(c),$CLJS.U,HA,b);if($CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(c))),LA)){GA(c);var d=SA.g($CLJS.r($CLJS.r(c)));$CLJS.m(d)&&(d.g?d.g($CLJS.TA):d.call(null,$CLJS.TA));$CLJS.m(a)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(c))),a);c=$CLJS.Zz(cA.g($CLJS.r($CLJS.r(c))),$CLJS.bA);$CLJS.gg(b.Eb,c);$CLJS.gg(b.Bb,c)}else d=JA.g($CLJS.r($CLJS.r(c))),
a=d+($CLJS.m(a)?$CLJS.E(a):0),$CLJS.dj.D($CLJS.r(c),$CLJS.U,JA,a),FA(c,new iA(UA,b,d,a,null,null,null))};
ZA=function(){var a=$CLJS.Sz,b=HA.g($CLJS.r($CLJS.r(a))),c=WA.g(b);if($CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(a))),LA)){GA(a);$CLJS.m(c)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),c);var d=SA.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(d.g?d.g(XA):d.call(null,XA))}else d=JA.g($CLJS.r($CLJS.r(a))),c=d+($CLJS.m(c)?$CLJS.E(c):0),$CLJS.dj.D($CLJS.r(a),$CLJS.U,JA,c),FA(a,new jA(YA,b,d,c,null,null,null));$CLJS.dj.D($CLJS.r(a),$CLJS.U,HA,$CLJS.cv.g(b))};
$CLJS.$A=function(a){var b=null!=a?a.C&32768||$CLJS.t===a.Zf?!0:a.C?!1:$CLJS.Nb($CLJS.Kz,a):$CLJS.Nb($CLJS.Kz,a);return b?QA.g($CLJS.r($CLJS.r(a))):b};$CLJS.dB=function(a){var b=aB;$CLJS.m(b)&&(b=$CLJS.yb,b=$CLJS.m(b)?aB>=$CLJS.yb:b);$CLJS.Gb($CLJS.bB)?Rz.call(null,a):$CLJS.m(b)?$CLJS.Qc($CLJS.Sz,"..."):($CLJS.m(aB)&&(aB+=1),cB.call(null,a));return b};eB=function(a,b){if($CLJS.Gb(b.g?b.g(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.g(a),". It must be one of ",$CLJS.p.g(b)].join(""));};
gB=function(){var a=$CLJS.Gi;return $CLJS.m(a)?fB>=$CLJS.Gi:a};lB=function(a){eB(a,new $CLJS.ni(null,new $CLJS.k(null,4,[hB,null,iB,null,jB,null,kB,null],null),null));var b=$CLJS.Sz;$CLJS.dj.D($CLJS.r(b),$CLJS.U,$CLJS.dk,IA);var c=JA.g($CLJS.r($CLJS.r(b))),d=HA.g($CLJS.r($CLJS.r(b)));FA(b,new hA(mA,a,d,c,c,null,null,null))};
pB=function(a,b){eB(a,new $CLJS.ni(null,new $CLJS.k(null,2,[mB,null,$CLJS.nB,null],null),null));var c=$CLJS.Sz,d=HA.g($CLJS.r($CLJS.r(c)));if($CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(c))),LA)){GA(c);var e=AA.g(d);if($CLJS.m($CLJS.F.h?$CLJS.F.h(mB,a):$CLJS.F.call(null,mB,a)))a=$CLJS.r(wA.g(d));else if($CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.nB,a):$CLJS.F.call(null,$CLJS.nB,a)))a=$CLJS.Zz(cA.g($CLJS.r($CLJS.r(c))),$CLJS.bA);else throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));$CLJS.gg(e,b+a)}else e=
JA.g($CLJS.r($CLJS.r(c))),FA(c,new kA(oB,d,a,b,e,e,null,null,null))};uB=function(a,b,c){b="string"===typeof b?qB(b):b;c=rB(c);a:{var d=new $CLJS.rb,e=$CLJS.Gb(a)||!0===a?new $CLJS.gd(d):a,f=sB(b)&&$CLJS.Gb($CLJS.$A(e))?$CLJS.m($CLJS.$A(e))?e:$CLJS.RA(e):e,g=$CLJS.Sz;$CLJS.Sz=f;try{try{tB(b,c)}finally{e!==f&&Lz(f)}var l=$CLJS.Gb(a)?$CLJS.p.g(d):!0===a?$CLJS.Oz($CLJS.p.g(d)):null;break a}finally{$CLJS.Sz=g}l=void 0}return l};
wB=function(a,b){a=[$CLJS.p.g(a),"\n",$CLJS.p.g(vB),"\n",$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(b," "))),"^\n"].join("");throw Error(a);};xB=function(a,b,c,d,e,f){this.Wc=a;this.xb=b;this.uc=c;this.F=d;this.m=e;this.G=f;this.C=2230716170;this.K=139264};rB=function(a){a=$CLJS.A(a);return new xB(a,a,0,null,null,null)};
zB=function(a){var b=$CLJS.xk.g(a);if($CLJS.m(b))return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(b),new xB(yB.g(a),$CLJS.D(b),JA.g(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};AB=function(a){var b=zB(a);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);a="string"===typeof a?qB(a):a;return new $CLJS.S(null,2,5,$CLJS.T,[a,b],null)};CB=function(a,b){return b>=JA.g(a)?(b=JA.g(a)-b,BB.h?BB.h(a,b):BB.call(null,a,b)):new xB(yB.g(a),$CLJS.kg(b,yB.g(a)),b,null,null,null)};
BB=function(a,b){var c=JA.g(a)+b;return 0>b?CB(a,c):new xB(yB.g(a),$CLJS.kg(b,$CLJS.xk.g(a)),c,null,null,null)};DB=function(a,b,c,d,e,f,g){this.Ac=a;this.yc=b;this.Hc=c;this.offset=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};
fda=function(a,b){var c=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);d=$CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[EB,null,FB,null],null),null),c)?new $CLJS.S(null,2,5,$CLJS.T,[d,b],null):$CLJS.F.h(d,GB)?zB(b):$CLJS.F.h(d,HB)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.E($CLJS.xk.g(b)),b],null):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[c,new $CLJS.S(null,2,5,$CLJS.T,
[b,a],null)],null),d],null)};IB=function(a,b){b=Vz(fda,b,a);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oh.h($CLJS.P,a),b],null)};
RB=function(a,b,c){c=zB(c);var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.Ae(d))if($CLJS.F.h(JB,10))var e=[$CLJS.p.g(d),$CLJS.m(KB)?".":null].join("");else{e=$CLJS.p;var f=e.g;if($CLJS.m(KB)){var g=$CLJS.M.h(gda,JB);g=$CLJS.m(g)?g:["#",$CLJS.p.g(JB),"r"].join("")}else g=null;e=[f.call(e,g),$CLJS.p.g(LB(JB,d))].join("")}else e=null;a=$CLJS.m(e)?e:a.g?a.g(d):a.call(null,d);d=a.length;e=d+MB.g(b);e=e>=NB.g(b)?e:e+($CLJS.af(NB.g(b)-e-1,OB.g(b))+1)*OB.g(b);d=$CLJS.R.h($CLJS.p,$CLJS.ng(e-d,PB.g(b)));
$CLJS.m(FB.g(b))?QB.l($CLJS.G([[$CLJS.p.g(d),$CLJS.p.g(a)].join("")])):QB.l($CLJS.G([[$CLJS.p.g(a),$CLJS.p.g(d)].join("")]));return c};SB=function(a,b){return $CLJS.lf($CLJS.C(Wz(function(c){return 0<c?new $CLJS.S(null,2,5,$CLJS.T,[Nz(c,a),$CLJS.af(c,a)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,null],null)},b)))};LB=function(a,b){return 0===b?"0":$CLJS.R.h($CLJS.p,$CLJS.rg.h(function(c){return 10>c?$CLJS.Gu(Uz("0")+c):$CLJS.Gu(Uz("a")+(c-10))},SB(a,b)))};
hda=function(a,b){return $CLJS.lf($CLJS.C(Wz(function(c){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.A($CLJS.lf($CLJS.jg(a,c))),$CLJS.A($CLJS.kg(a,c))],null)},$CLJS.lf(b))))};
WB=function(a,b,c){c=zB(c);var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.Ae(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.F.h(d,Math.floor(d))){var e=0>d,f=LB(a,e?-d:d);a=$CLJS.m(EB.g(b))?function(){var g=$CLJS.rg.h(function(n){return $CLJS.R.h($CLJS.p,n)},hda(TB.g(b),f)),l=$CLJS.ng($CLJS.E(g),UB.g(b));return $CLJS.R.h($CLJS.p,$CLJS.D($CLJS.og.h(l,g)))}():f;a=e?["-",$CLJS.p.g(a)].join(""):$CLJS.m(FB.g(b))?["+",$CLJS.p.g(a)].join(""):a;a=a.length<NB.g(b)?
[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(NB.g(b)-a.length,PB.g(b)))),$CLJS.p.g(a)].join(""):a;QB.l($CLJS.G([a]))}else RB($CLJS.VB,new $CLJS.k(null,5,[NB,NB.g(b),OB,1,MB,0,PB,PB.g(b),FB,!0],null),rB(new $CLJS.S(null,1,5,$CLJS.T,[d],null)));return c};
ZB=function(a){var b=$CLJS.af(a,100);a=Nz(a,100);var c=0<b?[$CLJS.p.g($CLJS.Td(XB,b))," hundred"].join(""):null,d=$CLJS.p,e=d.g;if(0<a)if(20>a)var f=$CLJS.Td(XB,a);else{f=$CLJS.af(a,10);var g=Nz(a,10);f=[$CLJS.p.g(0<f?$CLJS.Td(YB,f):null),0<f&&0<g?"-":null,$CLJS.p.g(0<g?$CLJS.Td(XB,g):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
aC=function(a,b){var c=$CLJS.E(a),d=$CLJS.Lg;--c;var e=$CLJS.C(a);for(a=$CLJS.D(a);;){if(null==a)return[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.pg(", ",d))),$CLJS.je(e)||$CLJS.je(d)?null:", ",$CLJS.p.g(e),!$CLJS.je(e)&&0<c+b?[" ",$CLJS.p.g($CLJS.Td($B,c+b))].join(""):null].join("");d=$CLJS.je(e)?d:$CLJS.kf.h(d,[$CLJS.p.g(e)," ",$CLJS.p.g($CLJS.Td($B,c+b))].join(""));--c;e=$CLJS.C(a);a=$CLJS.D(a)}};
bC=function(a,b){b=zB(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=SB(10,c);c=$CLJS.Lg;for(var e=$CLJS.E(d)-1;;)if($CLJS.je(d)){QB.l($CLJS.G([$CLJS.R.h($CLJS.p,c)]));break}else{var f=$CLJS.C(d);c=$CLJS.F.h(0,f)?c:$CLJS.kf.h(c,$CLJS.Td($CLJS.Td(a,e),f-1));--e;d=$CLJS.D(d)}}else WB(10,new $CLJS.k(null,5,[NB,0,PB," ",UB,",",TB,3,EB,!0],null),rB(new $CLJS.S(null,1,5,$CLJS.T,[c],null)));return b};
jda=function(a,b){a=zB(b);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);var c=Uz(b);b=c&127;c&=128;var d=$CLJS.M.h(ida,b);0<c&&QB.l($CLJS.G(["Meta-"]));QB.l($CLJS.G([$CLJS.m(d)?d:32>b?["Control-",$CLJS.p.g($CLJS.Gu(b+64))].join(""):$CLJS.F.h(b,127)?"Control-?":$CLJS.Gu(b)]));return a};
kda=function(a,b){var c=zB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);a=cC.g(a);if($CLJS.m($CLJS.F.h?$CLJS.F.h("o",a):$CLJS.F.call(null,"o",a)))uB(!0,"\\o~3,'0o",$CLJS.G([Uz(b)]));else if($CLJS.m($CLJS.F.h?$CLJS.F.h("u",a):$CLJS.F.call(null,"u",a)))uB(!0,"\\u~4,'0x",$CLJS.G([Uz(b)]));else if($CLJS.m($CLJS.F.h?$CLJS.F.h(null,a):$CLJS.F.call(null,null,a)))$CLJS.Qc($CLJS.Sz,$CLJS.m($CLJS.F.h?$CLJS.F.h("\b",b):$CLJS.F.call(null,"\b",b))?"\\backspace":$CLJS.m($CLJS.F.h?$CLJS.F.h(" ",b):$CLJS.F.call(null,
" ",b))?"\\space":$CLJS.m($CLJS.F.h?$CLJS.F.h("\t",b):$CLJS.F.call(null,"\t",b))?"\\tab":$CLJS.m($CLJS.F.h?$CLJS.F.h("\n",b):$CLJS.F.call(null,"\n",b))?"\\newline":$CLJS.m($CLJS.F.h?$CLJS.F.h("\f",b):$CLJS.F.call(null,"\f",b))?"\\formfeed":$CLJS.m($CLJS.F.h?$CLJS.F.h("\r",b):$CLJS.F.call(null,"\r",b))?"\\return":$CLJS.m($CLJS.F.h?$CLJS.F.h('"',b):$CLJS.F.call(null,'"',b))?'\\"':$CLJS.m($CLJS.F.h?$CLJS.F.h("\\",b):$CLJS.F.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.g(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.g(a)].join(""));return c};lda=function(a,b){b=zB(b);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);QB.l($CLJS.G([a]));return b};fC=function(a){a=$CLJS.C(a);return $CLJS.F.h(dC,a)||$CLJS.F.h(eC,a)};
jC=function(a,b,c){return $CLJS.Zd(Vz(function(d,e){if(fC(e))return new $CLJS.S(null,2,5,$CLJS.T,[null,e],null);e=IB(gC.g(d),e);var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);var g=Xz(f);f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);f=$CLJS.U.j(f,hC,c);return new $CLJS.S(null,2,5,$CLJS.T,[null,$CLJS.R.h(iC.g(d),new $CLJS.S(null,3,5,$CLJS.T,[f,e,g],null))],null)},b,a))};
kC=function(a){a=$CLJS.p.g(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.p.g(a.length-1)],null):new $CLJS.S(null,2,5,$CLJS.T,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.g(c-1)],null):0>c?new $CLJS.S(null,2,5,$CLJS.T,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.S(null,2,5,$CLJS.T,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);a:if(c=$CLJS.E(b),0<c&&$CLJS.F.h($CLJS.Td(b,
$CLJS.E(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.F.h($CLJS.Td(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.E(c);if(0<d&&$CLJS.F.h($CLJS.Td(c,0),"0"))for(var e=0;;){if($CLJS.F.h(e,d)||!$CLJS.F.h($CLJS.Td(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.E(b)-$CLJS.E(c);a=0<$CLJS.E(a)&&$CLJS.F.h($CLJS.Td(a,0),"+")?a.substring(1):a;return $CLJS.je(c)?new $CLJS.S(null,2,5,$CLJS.T,["0",0],null):new $CLJS.S(null,2,5,$CLJS.T,[c,parseInt(a,10)-b],null)};
lC=function(a,b,c,d){if($CLJS.m($CLJS.m(c)?c:d)){var e=$CLJS.E(a);d=$CLJS.m(d)?2>d?2:d:0;$CLJS.m(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.F.h(c,0)?new $CLJS.S(null,4,5,$CLJS.T,[["0",$CLJS.p.g(a)].join(""),b+1,1,e+1],null):new $CLJS.S(null,4,5,$CLJS.T,[a,b,c,e],null);c=$CLJS.J(f,0,null);e=$CLJS.J(f,1,null);d=$CLJS.J(f,2,null);f=$CLJS.J(f,3,null);if($CLJS.m(d)){if(0>d)return new $CLJS.S(null,3,5,$CLJS.T,["0",0,!1],null);if(f>d){b=$CLJS.Td(c,d);a=c.substring(0,d);if(Uz(b)>=Uz("5")){a:for(b=
$CLJS.E(a)-1,c=b|0;;){if(0>c){b=$CLJS.R.j($CLJS.p,"1",$CLJS.ng(b+1,"0"));break a}if($CLJS.F.h("9",a.charAt(c)))--c;else{b=$CLJS.R.D($CLJS.p,a.substring(0,c),$CLJS.Gu(Uz(a.charAt(c))+1),$CLJS.ng(b-c,"0"));break a}}a=$CLJS.E(b)>a.length;c=$CLJS.T;a&&(d=$CLJS.E(b)-1,b=b.substring(0,d));return new $CLJS.S(null,3,5,c,[b,e,a],null)}return new $CLJS.S(null,3,5,$CLJS.T,[a,e,!1],null)}}}return new $CLJS.S(null,3,5,$CLJS.T,[a,b,!1],null)};
mC=function(a,b,c){var d=0>b?new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(-b-1,"0"))),$CLJS.p.g(a)].join(""),-1],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);a=$CLJS.J(d,0,null);var e=$CLJS.J(d,1,null);d=$CLJS.E(a);c=$CLJS.m(c)?e+c+1:e+1;c=d<c?[$CLJS.p.g(a),$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.g(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
mda=function(a,b){return 0>b?[".",$CLJS.p.g(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
qC=function(a,b){var c=nC.g(a),d=oC.g(a);b=zB(b);var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var f=0>e?new $CLJS.S(null,2,5,$CLJS.T,["-",-e],null):new $CLJS.S(null,2,5,$CLJS.T,["+",e],null),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);f=kC(f);var l=$CLJS.J(f,0,null),n=$CLJS.J(f,1,null)+$CLJS.av.g(a);f=function(){var x=FB.g(a);return $CLJS.m(x)?x:0>e}();var q=$CLJS.Gb(d)&&$CLJS.E(l)-1<=n,u=lC(l,n,d,$CLJS.m(c)?c-($CLJS.m(f)?1:0):null);l=$CLJS.J(u,0,null);n=$CLJS.J(u,1,null);u=$CLJS.J(u,2,null);l=mC(l,$CLJS.m(u)?
n+1:n,d);d=$CLJS.m($CLJS.m(c)?$CLJS.m(d)?1<=d&&$CLJS.F.h(l.charAt(0),"0")&&$CLJS.F.h(l.charAt(1),".")&&l.length>c-($CLJS.m(f)?1:0):d:c)?l.substring(1):l;n=$CLJS.F.h($CLJS.C(d),".");if($CLJS.m(c)){l=d.length;l=$CLJS.m(f)?l+1:l;n=n&&!(l>=c);q=q&&!(l>=c);var v=n||q?l+1:l;$CLJS.m(function(){var x=v>c;return x?pC.g(a):x}())?QB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(c,pC.g(a)))])):QB.l($CLJS.G([[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(c-v,PB.g(a)))),$CLJS.p.g($CLJS.m(f)?g:null),n?"0":null,d,q?"0":null].join("")]))}else QB.l($CLJS.G([[$CLJS.p.g($CLJS.m(f)?
g:null),n?"0":null,d,q?"0":null].join("")]));return b};
tC=function(a,b){b=zB(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=kC(0>c?-c:c);$CLJS.J(d,0,null);for($CLJS.J(d,1,null);;){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null),g=nC.g(a),l=oC.g(a),n=rC.g(a),q=$CLJS.av.g(a),u=function(){var I=sC.g(a);return $CLJS.m(I)?I:"E"}();d=function(){var I=FB.g(a);return $CLJS.m(I)?I:0>c}();var v=0>=q,x=f-(q-1),y=$CLJS.p.g(Math.abs(x));u=[$CLJS.p.g(u),0>x?"-":"+",$CLJS.p.g($CLJS.m(n)?$CLJS.R.h($CLJS.p,$CLJS.ng(n-y.length,"0")):null),y].join("");var B=u.length;
x=$CLJS.E(e);e=[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(-q,"0"))),$CLJS.p.g(e),$CLJS.p.g($CLJS.m(l)?$CLJS.R.h($CLJS.p,$CLJS.ng(l-(x-1)-(0>q?-q:0),"0")):null)].join("");x=$CLJS.m(g)?g-B:null;e=lC(e,0,$CLJS.F.h(q,0)?l-1:0<q?l:0>q?l-1:null,$CLJS.m(x)?x-($CLJS.m(d)?1:0):null);x=$CLJS.J(e,0,null);$CLJS.J(e,1,null);y=$CLJS.J(e,2,null);e=mda(x,q);l=$CLJS.F.h(q,$CLJS.E(x))&&null==l;if($CLJS.Gb(y)){if($CLJS.m(g)){f=e.length+B;f=$CLJS.m(d)?f+1:f;var H=(v=v&&!$CLJS.F.h(f,g))?f+1:f;f=l&&H<g;$CLJS.m(function(){var I=
H>g;I||(I=n,I=$CLJS.m(I)?B-2>n:I);return $CLJS.m(I)?pC.g(a):I}())?QB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(g,pC.g(a)))])):QB.l($CLJS.G([[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(g-H-(f?1:0),PB.g(a)))),$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else QB.l($CLJS.G([[$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.S(null,2,5,$CLJS.T,[x,f+1],null)}return b};
nda=function(a,b,c){var d=zB(b),e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);d=kC(0>e?-e:e);var f=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var g=nC.g(a),l=oC.g(a),n=rC.g(a);d=$CLJS.F.h(e,0)?0:d+1;e=$CLJS.m(n)?n+2:4;g=$CLJS.m(g)?g-e:null;$CLJS.m(l)?f=l:(f=$CLJS.E(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=qC(new $CLJS.k(null,6,[nC,g,oC,d,$CLJS.av,0,pC,pC.g(a),PB,PB.g(a),FB,FB.g(a)],null),b,c),QB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(e," "))])),a):tC(a,b,c)};
oda=function(a,b){b=zB(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=kC(Math.abs(c)),e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null),g=oC.g(a),l=$CLJS.uC.g(a);d=nC.g(a);var n=function(){var u=FB.g(a);return $CLJS.m(u)?u:0>c}(),q=lC(e,f,g,null);e=$CLJS.J(q,0,null);f=$CLJS.J(q,1,null);q=$CLJS.J(q,2,null);g=mC(e,$CLJS.m(q)?f+1:f,g);l=[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(l-g.indexOf("."),"0"))),g].join("");g=l.length+($CLJS.m(n)?1:0);QB.l($CLJS.G([[$CLJS.m(function(){var u=EB.g(a);return $CLJS.m(u)?n:
u}())?0>c?"-":"+":null,$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(d-g,PB.g(a)))),$CLJS.m(function(){var u=$CLJS.Gb(EB.g(a));return u?n:u}())?0>c?"-":"+":null,l].join("")]));return b};pda=function(a,b){var c=vC.g(a);c=$CLJS.m(c)?new $CLJS.S(null,2,5,$CLJS.T,[c,b],null):zB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var d=$CLJS.wC.g(a);b=0>b||b>=$CLJS.E(d)?$CLJS.C($CLJS.Vu.g(a)):$CLJS.Td(d,b);return $CLJS.m(b)?jC(b,c,hC.g(a)):c};
qda=function(a,b){var c=zB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var d=$CLJS.wC.g(a);b=$CLJS.m(b)?$CLJS.Zd(d):$CLJS.C(d);return $CLJS.m(b)?jC(b,c,hC.g(a)):c};rda=function(a,b){var c=zB(b),d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var e=$CLJS.wC.g(a);e=$CLJS.m(d)?$CLJS.C(e):null;return $CLJS.m(d)?$CLJS.m(e)?jC(e,b,hC.g(a)):b:c};
sda=function(a,b){var c=xC.g(a),d=$CLJS.C($CLJS.wC.g(a));d=$CLJS.je(d)?AB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=zB(d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=0;e=rB(e);for(var g=-1;;){if($CLJS.Gb(c)&&$CLJS.F.h(JA.g(e),g)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");g=$CLJS.je($CLJS.xk.g(e))&&($CLJS.Gb(EB.g(yC.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=jC(b,e,hC.g(a));if($CLJS.F.h(dC,$CLJS.C(g)))return d;
f+=1;var l=JA.g(e);e=g;g=l}};tda=function(a,b){var c=xC.g(a),d=$CLJS.C($CLJS.wC.g(a));d=$CLJS.je(d)?AB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=zB(d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);for(var f=0;;){var g=$CLJS.je(e)&&($CLJS.Gb(EB.g(yC.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=jC(b,rB($CLJS.C(e)),rB($CLJS.D(e)));if($CLJS.F.h(eC,$CLJS.C(g)))return d;f+=1;e=$CLJS.D(e)}};
uda=function(a,b){var c=xC.g(a),d=$CLJS.C($CLJS.wC.g(a)),e=$CLJS.je(d)?AB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(e,0,null);d=0;e=$CLJS.J(e,1,null);for(var f=-1;;){if($CLJS.Gb(c)&&$CLJS.F.h(JA.g(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.je($CLJS.xk.g(e))&&($CLJS.Gb(EB.g(yC.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=jC(b,e,hC.g(a));if($CLJS.F.h(dC,$CLJS.C(f)))return $CLJS.Zd(f);d+=1;var g=JA.g(e);e=f;f=g}};
vda=function(a,b){var c=xC.g(a),d=$CLJS.C($CLJS.wC.g(a)),e=$CLJS.je(d)?AB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(e,0,null);d=0;for(e=$CLJS.J(e,1,null);;){var f=$CLJS.je($CLJS.xk.g(e))&&($CLJS.Gb(EB.g(yC.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=$CLJS.xk.g(e);f=$CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(f),new xB(yB.g(e),$CLJS.D(f),JA.g(e)+1,null,null,null)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,e],null);e=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=jC(b,rB(e),f);
if($CLJS.F.h(eC,$CLJS.C(e)))return f;e=f;d+=1}};xda=function(a,b){if($CLJS.m(EB.g(yC.g(a)))){var c=$CLJS.wC.g(a),d=$CLJS.E(c),e=1<d?$CLJS.Pl.g(gC.g($CLJS.C($CLJS.C(c)))):$CLJS.m(EB.g(a))?"(":null,f=$CLJS.Td(c,1<d?1:0);c=2<d?$CLJS.Pl.g(gC.g($CLJS.C($CLJS.Td(c,2)))):$CLJS.m(EB.g(a))?")":null;d=zB(b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var g=fB,l=aB;fB+=1;aB=0;try{VA(e,c),jC(f,rB(b),hC.g(a)),ZA()}finally{aB=l,fB=g}}a=d}else a=wda(a,b);return a};
zC=function(a,b,c){for(var d=$CLJS.Lg;;){if($CLJS.je(a))return new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);var e=$CLJS.C(a);a:{var f=new $CLJS.rb,g=$CLJS.Sz;$CLJS.Sz=new $CLJS.gd(f);try{var l=new $CLJS.S(null,2,5,$CLJS.T,[jC(e,b,c),$CLJS.p.g(f)],null);break a}finally{$CLJS.Sz=g}l=void 0}b=$CLJS.J(l,0,null);e=$CLJS.J(l,1,null);if($CLJS.F.h(dC,$CLJS.C(b)))return new $CLJS.S(null,2,5,$CLJS.T,[d,$CLJS.Zd(b)],null);a=$CLJS.D(a);d=$CLJS.kf.h(d,e)}};
wda=function(a,b){var c=function(){var Y=$CLJS.Vu.g(a);return $CLJS.m(Y)?zC(Y,b,hC.g(a)):null}(),d=$CLJS.J(c,0,null);d=$CLJS.J(d,0,null);c=$CLJS.J(c,1,null);var e=$CLJS.m(c)?c:b;c=function(){var Y=AC.g(a);return $CLJS.m(Y)?IB(Y,e):null}();var f=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var g=$CLJS.m(c)?c:e;c=function(){var Y=$CLJS.C(BC.g(f));return $CLJS.m(Y)?Y:0}();var l=function(){var Y=$CLJS.C(CC.g(f));return $CLJS.m(Y)?Y:$CLJS.Zz($CLJS.Sz,$CLJS.Zl)}(),n=$CLJS.wC.g(a);g=zC(n,g,hC.g(a));var q=$CLJS.J(g,
0,null);g=$CLJS.J(g,1,null);var u=function(){var Y=$CLJS.E(q)-1+($CLJS.m(EB.g(a))?1:0)+($CLJS.m(FB.g(a))?1:0);return 1>Y?1:Y}();n=$CLJS.Ve($CLJS.Gm,$CLJS.rg.h($CLJS.E,q));var v=NB.g(a),x=MB.g(a),y=OB.g(a),B=n+u*x;v=B<=v?v:v+y*(1+$CLJS.af(B-v-1,y));var H=v-n;n=function(){var Y=$CLJS.af(H,u);return x>Y?x:Y}();y=H-n*u;n=$CLJS.R.h($CLJS.p,$CLJS.ng(n,PB.g(a)));$CLJS.m($CLJS.m(d)?$CLJS.Zz(cA.g($CLJS.r($CLJS.r($CLJS.Sz))),$CLJS.bA)+c+v>l:d)&&QB.l($CLJS.G([d]));c=y;for(var I=q,Q=function(){var Y=EB.g(a);
return $CLJS.m(Y)?Y:$CLJS.F.h($CLJS.E(I),1)&&$CLJS.Gb(FB.g(a))}();;)if($CLJS.A(I))QB.l($CLJS.G([[$CLJS.p.g($CLJS.Gb(Q)?$CLJS.C(I):null),$CLJS.p.g($CLJS.m(function(){var Y=Q;return $CLJS.m(Y)?Y:(Y=$CLJS.D(I))?Y:FB.g(a)}())?n:null),$CLJS.p.g(0<c?PB.g(a):null)].join("")])),--c,I=d=$CLJS.m(Q)?I:$CLJS.D(I),Q=!1;else break;return g};DC=function(a,b){this.La=a;this.Yi=b;this.C=1074135040;this.K=0};yda=function(a){return new DC(a,$CLJS.P)};EC=function(a,b){this.La=a;this.Zi=b;this.C=1074135040;this.K=0};
zda=function(a){return new EC(a,$CLJS.P)};
Ada=function(a,b){var c=$CLJS.C(a);return $CLJS.R.h($CLJS.p,$CLJS.C(Wz(function(d){if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.m(e)?e.index+1:e;return $CLJS.m(e)?new $CLJS.S(null,2,5,$CLJS.T,[[d.substring(0,e),$CLJS.Td(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.S(null,2,5,$CLJS.T,[d,null],null)},$CLJS.m($CLJS.m(b)?$CLJS.m(c)?Jz(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
FC=function(a,b,c){this.La=a;this.we=b;this.$i=c;this.C=1074135040;this.K=0};Bda=function(a){var b=$CLJS.cj.g(!0);return new FC(a,b,$CLJS.P)};GC=function(a,b,c){this.La=a;this.ke=b;this.aj=c;this.C=1074135040;this.K=0};Cda=function(a){var b=$CLJS.cj.g(!1);return new GC(a,b,$CLJS.P)};Dda=function(a,b){var c=$CLJS.m(EB.g(a))?$CLJS.nB:mB;pB(c,$CLJS.uC.g(a));return b};Eda=function(a,b){a=$CLJS.m(EB.g(a))?$CLJS.m(FB.g(a))?hB:jB:$CLJS.m(FB.g(a))?iB:kB;lB(a);return b};
Gda=function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null),d=$CLJS.J(a,2,null);a=new RegExp(Fda.source,"g");var e=a.exec(b);return $CLJS.m(e)?(d=$CLJS.C(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.F.h(",",$CLJS.Td(b,0))?new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[d,c],null),new $CLJS.S(null,3,5,$CLJS.T,[b.substring(1),a+1,!0],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[d,c],null),new $CLJS.S(null,3,5,$CLJS.T,[b,a,!1],null)],null)):$CLJS.m(d)?wB("Badly formed parameters in format directive",
c):new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,2,5,$CLJS.T,[b,c],null)],null)};Hda=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(b.length,0)?null:$CLJS.F.h(b.length,1)&&$CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,["V",null,"v",null],null),null),$CLJS.Td(b,0))?GB:$CLJS.F.h(b.length,1)&&$CLJS.F.h("#",$CLJS.Td(b,0))?HB:$CLJS.F.h(b.length,2)&&$CLJS.F.h("'",$CLJS.Td(b,0))?$CLJS.Td(b,1):parseInt(b,10),a],null)};
Jda=function(a,b){return Wz(function(c){var d=$CLJS.J(c,0,null),e=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,3,5,$CLJS.T,[d,e,c],null)],null);var f=$CLJS.M.h(Ida,$CLJS.C(d));return $CLJS.m(f)?$CLJS.Ie(c,f)?wB(['Flag "',$CLJS.p.g($CLJS.C(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[d.substring(1),e+1,$CLJS.U.j(c,f,new $CLJS.S(null,2,5,$CLJS.T,[!0,e],null))],
null)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,3,5,$CLJS.T,[d,e,c],null)],null)},new $CLJS.S(null,3,5,$CLJS.T,[a,b,$CLJS.P],null))};
Kda=function(a,b){var c=HC.g(a);$CLJS.m(function(){var d=$CLJS.Gb(FB.g(c));return d?FB.g(b):d}())&&wB(['"@" is an illegal flag for format directive "',$CLJS.p.g(IC.g(a)),'"'].join(""),$CLJS.Td(FB.g(b),1));$CLJS.m(function(){var d=$CLJS.Gb(EB.g(c));return d?EB.g(b):d}())&&wB(['":" is an illegal flag for format directive "',$CLJS.p.g(IC.g(a)),'"'].join(""),$CLJS.Td(EB.g(b),1));$CLJS.m(function(){var d=$CLJS.Gb(JC.g(c));return d?(d=FB.g(b),$CLJS.m(d)?EB.g(b):d):d}())&&wB(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.g(IC.g(a)),'"'].join(""),function(){var d=$CLJS.Td(EB.g(b),1),e=$CLJS.Td(FB.g(b),1);return d<e?d:e}())};
Mda=function(a,b,c,d){Kda(a,c);$CLJS.E(b)>$CLJS.E(gC.g(a))&&wB(uB(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.G([IC.g(a),$CLJS.E(b),$CLJS.E(gC.g(a))])),$CLJS.Zd($CLJS.C(b)));$CLJS.Ci($CLJS.rg.j(function(e,f){var g=$CLJS.C(e);return null==g||$CLJS.Ie(Lda,g)||$CLJS.F.h($CLJS.Zd($CLJS.Zd(f)),$CLJS.Ob(g))?null:wB(["Parameter ",$CLJS.ui($CLJS.C(f)),' has bad type in directive "',$CLJS.p.g(IC.g(a)),'": ',$CLJS.p.g($CLJS.Ob(g))].join(""),
$CLJS.Zd(e))},b,gC.g(a)));return $CLJS.gn.l($CLJS.G([$CLJS.oh.h($CLJS.P,$CLJS.lf(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);x=$CLJS.J(x,0,null);u.add(new $CLJS.S(null,2,5,$CLJS.T,[y,new $CLJS.S(null,2,5,$CLJS.T,[x,d],null)],null));v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):
$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);n=$CLJS.J(n,0,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,new $CLJS.S(null,2,5,$CLJS.T,[n,d],null)],null),g($CLJS.zd(l)))}return null}},null,null)}(gC.g(a))}())),$CLJS.Sb(function(e,f){return $CLJS.R.j($CLJS.U,e,f)},$CLJS.P,$CLJS.ug(function(e){return $CLJS.C($CLJS.Td(e,1))},$CLJS.Qz($CLJS.gi(gC.g(a)),b))),c]))};
Oda=function(a,b){b=Wz(Gda,new $CLJS.S(null,3,5,$CLJS.T,[a,b,!1],null));a=$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);b=Jda(b,c);$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);c=$CLJS.C(d);var f=$CLJS.M.h(Nda,c.toUpperCase()),g=$CLJS.m(f)?Mda(f,$CLJS.rg.h(Hda,a),b,e):null;$CLJS.Gb(c)&&wB("Format string ended in the middle of a directive",e);$CLJS.Gb(f)&&wB(['Directive "',$CLJS.p.g(c),'" is undefined'].join(""),
e);return new $CLJS.S(null,2,5,$CLJS.T,[new DB(function(){var l=KC.g(f);return l.h?l.h(g,e):l.call(null,g,e)}(),f,g,e,null,null,null),function(){var l=d.substring(1),n=e+1;if($CLJS.F.h("\n",IC.g(f))&&$CLJS.Gb(EB.g(g)))a:{var q=new $CLJS.S(null,2,5,$CLJS.T,[" ","\t"],null);q=$CLJS.ke(q)?$CLJS.si(q):$CLJS.pi([q]);for(var u=0;;){var v;(v=$CLJS.F.h(u,$CLJS.E(l)))||(v=$CLJS.Td(l,u),v=q.g?q.g(v):q.call(null,v),v=$CLJS.Gb(v));if(v){q=u;break a}u+=1}}else q=0;return new $CLJS.S(null,2,5,$CLJS.T,[l.substring(q),
n+q],null)}()],null)};LC=function(a,b){return new DB(function(c,d){QB.l($CLJS.G([a]));return d},null,new $CLJS.k(null,1,[$CLJS.Pl,a],null),b,null,null,null)};OC=function(a,b){var c=Pda(MC.g(NC.g(a)),$CLJS.Xu.g(a),b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[new DB(iC.g(a),NC.g(a),$CLJS.gn.l($CLJS.G([gC.g(a),Yca(b,$CLJS.Xu.g(a))])),$CLJS.Xu.g(a),null,null,null),c],null)};
Qda=function(a,b,c){return Wz(function(d){if($CLJS.je(d))return wB("No closing bracket found.",b);var e=$CLJS.C(d);d=$CLJS.D(d);if($CLJS.m($CLJS.PC.g(MC.g(NC.g(e)))))e=OC(e,d);else if($CLJS.F.h($CLJS.PC.g(a),IC.g(NC.g(e))))e=new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[QC,gC.g(e),null,d],null)],null);else{var f=$CLJS.Zu.g(MC.g(NC.g(e)));f=$CLJS.m(f)?EB.g(gC.g(e)):f;e=$CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Vu,null,gC.g(e),d],null)],null):
$CLJS.m($CLJS.Zu.g(MC.g(NC.g(e))))?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Zu,null,null,d],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[e,d],null)}return e},c)};
Pda=function(a,b,c){return $CLJS.Zd(Wz(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);d=$CLJS.J(d,2,null);var g=Qda(a,b,d);d=$CLJS.J(g,0,null);var l=$CLJS.J(g,1,null);g=$CLJS.J(l,0,null);var n=$CLJS.J(l,1,null),q=$CLJS.J(l,2,null);l=$CLJS.J(l,3,null);return $CLJS.F.h(g,QC)?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.RC.l($CLJS.qg,$CLJS.G([e,$CLJS.Pf([$CLJS.m(f)?$CLJS.Vu:$CLJS.wC,new $CLJS.S(null,1,5,$CLJS.T,[d],null),yC,n])])),l],null)],null):$CLJS.F.h(g,$CLJS.Vu)?
$CLJS.m($CLJS.Vu.g(e))?wB('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Gb($CLJS.Vu.g(a))?wB('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.F.h(SC,$CLJS.Vu.g(a))&&$CLJS.A($CLJS.wC.g(e))?wB('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.F.h(SC,$CLJS.Vu.g(a))?new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RC.l($CLJS.qg,$CLJS.G([e,new $CLJS.k(null,2,[$CLJS.Vu,new $CLJS.S(null,1,5,
$CLJS.T,[d],null),AC,q],null)])),!1,l],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RC.l($CLJS.qg,$CLJS.G([e,new $CLJS.k(null,1,[$CLJS.wC,new $CLJS.S(null,1,5,$CLJS.T,[d],null)],null)])),!0,l],null)],null):$CLJS.F.h(g,$CLJS.Zu)?$CLJS.m(f)?wB('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Gb(TC.g(a))?wB('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.RC.l($CLJS.qg,$CLJS.G([e,new $CLJS.k(null,1,[$CLJS.wC,new $CLJS.S(null,1,5,$CLJS.T,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.wC,$CLJS.Lg],null),!1,c],null)))};Rda=function(a){return $CLJS.C(Wz(function(b){var c=$CLJS.C(b);b=$CLJS.D(b);var d=MC.g(NC.g(c));return $CLJS.m($CLJS.PC.g(d))?OC(c,b):new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)},a))};
qB=function(a){var b=vB;vB=a;try{return Rda($CLJS.C(Wz(function(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.S(null,2,5,$CLJS.T,[LC(d,c),new $CLJS.S(null,2,5,$CLJS.T,["",c+d.length],null)],null):0===e?Oda(d.substring(1),c+1):new $CLJS.S(null,2,5,$CLJS.T,[LC(d.substring(0,e),c),new $CLJS.S(null,2,5,$CLJS.T,[d.substring(e),e+c],null)],null)},new $CLJS.S(null,2,5,$CLJS.T,[a,0],null))))}finally{vB=
b}};tB=function(a,b){Vz(function(c,d){if(fC(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);d=IB(gC.g(c),d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=Xz(e);e=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=$CLJS.U.j(e,hC,d);return new $CLJS.S(null,2,5,$CLJS.T,[null,$CLJS.R.h(iC.g(c),new $CLJS.S(null,3,5,$CLJS.T,[e,d,f],null))],null)},b,a);return null};
VC=function(a){var b=$CLJS.C(a);b=UC.g?UC.g(b):UC.call(null,b);return $CLJS.m($CLJS.m(b)?$CLJS.F.h(2,$CLJS.E(a)):b)?($CLJS.Qc($CLJS.Sz,b),$CLJS.dB($CLJS.Zd(a)),!0):null};
WC=function(a){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var b=fB,c=aB;fB+=1;aB=0;try{VA("[","]");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e&&($CLJS.dB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.Sz," ");lB(kB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.Sz,"...");break}ZA()}finally{aB=c,fB=b}}return null};
XC=function(a){var b=$CLJS.pe(a)?null:function(){var n=new $CLJS.ud(function(){return $CLJS.Wi},Sda,$CLJS.Sh([$CLJS.pm,$CLJS.Dk,$CLJS.V,$CLJS.fm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.vm,$CLJS.Zj,$CLJS.W,$CLJS.em],[!0,$CLJS.Mj,Tda,"cljs/core.cljs",15,1,10543,10543,$CLJS.X(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Mk],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.m($CLJS.Wi)?$CLJS.Wi.J:null]));return n.g?n.g(a):n.call(null,a)}(),c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.m(b)?
b:a,e=$CLJS.m(c)?["#:",$CLJS.p.g(c),"{"].join(""):"{";if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{c=fB;b=aB;fB+=1;aB=0;try{VA(e,"}");e=0;for(var f=$CLJS.A(d);;){if($CLJS.Gb($CLJS.yb)||e<$CLJS.yb){if(f){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{d=fB;var g=aB;fB+=1;aB=0;try{VA(null,null),$CLJS.dB($CLJS.C($CLJS.C(f))),$CLJS.Qc($CLJS.Sz," "),lB(kB),aB=0,$CLJS.dB($CLJS.C($CLJS.D($CLJS.C(f)))),ZA()}finally{aB=g,fB=d}}if($CLJS.D(f)){$CLJS.Qc($CLJS.Sz,", ");lB(kB);d=e+1;var l=$CLJS.D(f);e=d;f=l;continue}}}else $CLJS.Qc($CLJS.Sz,
"...");break}ZA()}finally{aB=b,fB=c}}return null};YC=function(a){return $CLJS.Qc($CLJS.Sz,$CLJS.aj.l($CLJS.G([a])))};aD=function(a){return a instanceof $CLJS.ah?ZC:(null!=a?a.C&32768||$CLJS.t===a.Zf||(a.C?0:$CLJS.Nb($CLJS.Kz,a)):$CLJS.Nb($CLJS.Kz,a))?$C:a instanceof $CLJS.w?$CLJS.nk:$CLJS.we(a)?$CLJS.ql:$CLJS.oe(a)?$CLJS.Yl:$CLJS.qe(a)?$CLJS.Al:$CLJS.le(a)?$CLJS.Hk:null==a?null:$CLJS.Pj};
bD=function(a){return $CLJS.qe(a)?new $CLJS.S(null,2,5,$CLJS.T,["[","]"],null):new $CLJS.S(null,2,5,$CLJS.T,["(",")"],null)};
Uda=function(a){if($CLJS.ne(a)){var b=bD(a),c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null),e=$CLJS.A(a),f=$CLJS.C(e),g=$CLJS.D(e);if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var l=fB,n=aB,q=fB+1;fB=q;aB=0;try{VA(c,d);(function(){var Ea=cD("~w~:i");return function(){function kb(Fb){var Mb=null;if(0<arguments.length){Mb=0;for(var ac=Array(arguments.length-0);Mb<ac.length;)ac[Mb]=arguments[Mb+0],++Mb;Mb=new $CLJS.z(ac,0,null)}return lb.call(this,Mb)}function lb(Fb){Fb=rB(Fb);return tB(Ea,Fb)}kb.v=0;kb.B=function(Fb){Fb=
$CLJS.A(Fb);return lb(Fb)};kb.l=lb;return kb}()})()(f);for(var u=g;;)if($CLJS.A(u)){(function(){var Ea=cD(" ");return function(kb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=rB(Tb);return tB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u," ",Ea,l,n,q,0,b,c,d,a,e,f,g,f,g)})()();
var v=$CLJS.C(u);if($CLJS.ne(v)){var x=bD(v),y=$CLJS.J(x,0,null),B=$CLJS.J(x,1,null);if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var H=fB,I=aB,Q=fB+1;fB=Q;aB=0;try{VA(y,B);if($CLJS.F.h($CLJS.E(v),3)&&$CLJS.Zd(v)instanceof $CLJS.N){var Y=v,aa=$CLJS.J(Y,0,null),ha=$CLJS.J(Y,1,null),qa=$CLJS.J(Y,2,null);(function(){var Ea=cD("~w ~w ");return function(kb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+
0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=rB(Tb);return tB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~w ~w ",Ea,Y,aa,ha,qa,H,I,Q,0,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)})()(aa,ha);$CLJS.ne(qa)?function(){var Ea=$CLJS.qe(qa)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",kb="string"===typeof Ea?cD(Ea):Ea;return function(lb,Fb,Mb){return function(){function ac(Ub){var ge=null;if(0<arguments.length){ge=0;
for(var $z=Array(arguments.length-0);ge<$z.length;)$z[ge]=arguments[ge+0],++ge;ge=new $CLJS.z($z,0,null)}return Tb.call(this,ge)}function Tb(Ub){Ub=rB(Ub);return tB(Mb,Ub)}ac.v=0;ac.B=function(Ub){Ub=$CLJS.A(Ub);return Tb(Ub)};ac.l=Tb;return ac}()}(u,Ea,kb,Y,aa,ha,qa,H,I,Q,0,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()(qa):$CLJS.dB(qa)}else $CLJS.R.h(function(){var Ea=cD("~w ~:i~@{~w~^ ~:_~}");return function(kb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=
Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=rB(Tb);return tB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Ea,H,I,Q,0,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}(),v);ZA()}finally{aB=I,fB=H}}$CLJS.D(u)&&function(){var Ea=cD("~_");return function(kb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-
0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=rB(Tb);return tB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~_",Ea,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()()}else $CLJS.dB(v),$CLJS.D(u)&&function(){var Ea=cD("~:_");return function(kb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;
Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=rB(Tb);return tB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~:_",Ea,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()();u=$CLJS.D(u)}else break;ZA()}finally{aB=n,fB=l}}}else $CLJS.dB(a)};
Vda=function(a,b){$CLJS.A(a)&&($CLJS.m(b)?function(){var c=cD(" ~_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.z(l,0,null)}return e.call(this,g)}function e(f){f=rB(f);return tB(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;return d}()}()():function(){var c=cD(" ~@_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<
l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.z(l,0,null)}return e.call(this,g)}function e(f){f=rB(f);return tB(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;return d}()}()(),function(){var c=cD("~{~w~^ ~_~}");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.z(l,0,null)}return e.call(this,g)}function e(f){f=rB(f);return tB(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;
return d}()}()(a))};Wda=function(a){$CLJS.A(a)&&function(){var b=cD(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var g=Array(arguments.length-0);f<g.length;)g[f]=arguments[f+0],++f;f=new $CLJS.z(g,0,null)}return d.call(this,f)}function d(e){e=rB(e);return tB(b,e)}c.v=0;c.B=function(e){e=$CLJS.A(e);return d(e)};c.l=d;return c}()}()(a)};
eD=function(a){if($CLJS.D(a)){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d="string"===typeof $CLJS.C(c)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(c),$CLJS.D(c)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,c],null);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var e=$CLJS.oe($CLJS.C(d))?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(d),$CLJS.D(d)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var f=
fB,g=aB;fB+=1;aB=0;try{VA("(",")"),function(){var l=cD("~w ~1I~@_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=rB(u);return tB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}()(a,b),$CLJS.m(c)&&function(){var l=cD(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=rB(u);return tB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}()(c),$CLJS.m(d)&&function(){var l=cD(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=rB(u);return tB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};
n.l=q;return n}()}()(d),$CLJS.qe($CLJS.C(e))?Vda(e,$CLJS.m(c)?c:d):Wda(e),ZA()}finally{aB=g,fB=f}}return null}return dD(a)};
Xda=function(a){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var b=fB,c=aB;fB+=1;aB=0;try{VA("[","]");for(var d=0;;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if($CLJS.A(a)){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var e=fB,f=aB;fB+=1;aB=0;try{VA(null,null),$CLJS.dB($CLJS.C(a)),$CLJS.D(a)&&($CLJS.Qc($CLJS.Sz," "),lB(iB),$CLJS.dB($CLJS.Zd(a))),ZA()}finally{aB=f,fB=e}}if($CLJS.D($CLJS.zd(a))){$CLJS.Qc($CLJS.Sz," ");lB(kB);e=d+1;var g=$CLJS.D($CLJS.zd(a));d=e;a=g;continue}}}else $CLJS.Qc($CLJS.Sz,"...");
break}ZA()}finally{aB=c,fB=b}}};
fD=function(a){var b=$CLJS.C(a);if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var c=fB,d=aB;fB+=1;aB=0;try{VA("(",")"),$CLJS.D(a)&&$CLJS.qe($CLJS.Zd(a))?(function(){var e=cD("~w ~1I~@_");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.z(q,0,null)}return g.call(this,n)}function g(l){l=rB(l);return tB(e,l)}f.v=0;f.B=function(l){l=$CLJS.A(l);return g(l)};f.l=g;return f}()}()(b),Xda($CLJS.Zd(a)),function(){var e=
cD(" ~_~{~w~^ ~_~}");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.z(q,0,null)}return g.call(this,n)}function g(l){l=rB(l);return tB(e,l)}f.v=0;f.B=function(l){l=$CLJS.A(l);return g(l)};f.l=g;return f}()}()($CLJS.D($CLJS.zd(a)))):dD(a),ZA()}finally{aB=d,fB=c}}return null};
dD=function(a){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var b=fB,c=aB;fB+=1;aB=0;try{VA("(",")");pB(mB,1);for(var d=0,e=$CLJS.A(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e&&($CLJS.dB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.Sz," ");lB(kB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.Sz,"...");break}ZA()}finally{aB=c,fB=b}}return null};$CLJS.Sz=null;
$CLJS.RC=function RC(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return RC.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
$CLJS.RC.l=function(a,b){if($CLJS.m($CLJS.Xf($CLJS.Ye,b))){var c=function(d,e){var f=$CLJS.tc(e),g=$CLJS.uc(e);if($CLJS.Ie(d,f)){e=$CLJS.U.j;var l=$CLJS.M.h(d,f);g=a.h?a.h(l,g):a.call(null,l,g);d=e.call($CLJS.U,d,f,g)}else d=$CLJS.U.j(d,f,g);return d};return $CLJS.Ve(function(d,e){return $CLJS.Sb(c,$CLJS.m(d)?d:$CLJS.P,$CLJS.A(e))},b)}return null};$CLJS.RC.v=1;$CLJS.RC.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var gD=function gD(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return gD.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};gD.l=function(a){a=$CLJS.A(a);for(var b=$CLJS.Tc($CLJS.qh);;)if(a){var c=$CLJS.D(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.g($CLJS.C(a))].join(""));var d=$CLJS.D(c);b=$CLJS.rh.j(b,$CLJS.C(a),$CLJS.C(c));a=d}else return $CLJS.Vc(b)};gD.v=0;gD.B=function(a){return this.l($CLJS.A(a))};
$CLJS.VB=function VB(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return VB.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};$CLJS.VB.l=function(a){return $CLJS.Ti(a,$CLJS.U.j($CLJS.zb(),$CLJS.tb,!1))};$CLJS.VB.v=0;$CLJS.VB.B=function(a){return this.l($CLJS.A(a))};nA=new $CLJS.N(null,"trailing-white-space","trailing-white-space",1496006996);hD=new $CLJS.N(null,"relative-to","relative-to",-470100051);BA=new $CLJS.N(null,"intra-block-nl","intra-block-nl",1808826875);
sC=new $CLJS.N(null,"exponentchar","exponentchar",1986664222);Yda=new $CLJS.w(null,"when-let","when-let",-1383043480,null);iB=new $CLJS.N(null,"miser","miser",-556060186);$CLJS.nB=new $CLJS.N(null,"current","current",-1088038603);Zda=new $CLJS.w(null,"-\x3e","-\x3e",-2139605430,null);$da=new $CLJS.w(null,"..","..",-300507420,null);qA=new $CLJS.N(null,"end-pos","end-pos",-1643883926);zA=new $CLJS.N(null,"per-line-prefix","per-line-prefix",846941813);ZC=new $CLJS.N(null,"queue","queue",1455835879);
aea=new $CLJS.w(null,"if-let","if-let",1803593690,null);XA=new $CLJS.N(null,"end","end",-268185958);HB=new $CLJS.N(null,"remaining-arg-count","remaining-arg-count",-1216589335);HA=new $CLJS.N(null,"logical-blocks","logical-blocks",-1466339776);bea=new $CLJS.w(null,"defmacro","defmacro",2054157304,null);cea=new $CLJS.w(null,"when-first","when-first",821699168,null);UA=new $CLJS.N(null,"start-block-t","start-block-t",-373430594);dea=new $CLJS.w(null,"binding","binding",-2114503176,null);
MC=new $CLJS.N(null,"bracket-info","bracket-info",-1600092774);LA=new $CLJS.N(null,"writing","writing",-1486865108);GB=new $CLJS.N(null,"parameter-from-args","parameter-from-args",-758446196);SA=new $CLJS.N(null,"logical-block-callback","logical-block-callback",1612691194);vC=new $CLJS.N(null,"selector","selector",762528866);eea=new $CLJS.w(null,"struct","struct",325972931,null);AA=new $CLJS.N(null,"indent","indent",-148200125);fea=new $CLJS.w(null,"loop","loop",1244978678,null);
CC=new $CLJS.N(null,"max-columns","max-columns",1742323262);JC=new $CLJS.N(null,"both","both",-393648840);iD=new $CLJS.N(null,"colnum","colnum",2023796854);gea=new $CLJS.w(null,"doseq","doseq",221164135,null);$CLJS.jD=new $CLJS.N(null,"length","length",588987862);cC=new $CLJS.N(null,"char-format","char-format",-1016499218);kD=new $CLJS.N(null,"prefix","prefix",-265908465);eC=new $CLJS.N(null,"colon-up-arrow","colon-up-arrow",244853007);WA=new $CLJS.N(null,"suffix","suffix",367373057);
FB=new $CLJS.N(null,"at","at",1476951349);mA=new $CLJS.N(null,"nl-t","nl-t",-1608382114);IC=new $CLJS.N(null,"directive","directive",793559132);eda=new $CLJS.N(null,"buffer-level","buffer-level",928864731);hB=new $CLJS.N(null,"mandatory","mandatory",542802336);QA=new $CLJS.N(null,"pretty-writer","pretty-writer",-1222834267);tA=new $CLJS.N(null,"done-nl","done-nl",-381024340);hea=new $CLJS.w(null,"condp","condp",1054325175,null);yB=new $CLJS.N(null,"seq","seq",-1817803783);
iea=new $CLJS.w(null,"defn","defn",-126010802,null);OB=new $CLJS.N(null,"colinc","colinc",-584873385);dC=new $CLJS.N(null,"up-arrow","up-arrow",1705310333);QC=new $CLJS.N(null,"right-bracket","right-bracket",951856080);jea=new $CLJS.N(null,"radix","radix",857016463);SC=new $CLJS.N(null,"first","first",-644103046);dda=new $CLJS.N(null,"sections","sections",-886710106);$CLJS.TA=new $CLJS.N(null,"start","start",-355208981);kea=new $CLJS.w("cljs.core","unquote","cljs.core/unquote",1013085760,null);
lea=new $CLJS.w(null,"defn-","defn-",1097765044,null);mea=new $CLJS.w("cljs.core","deref","cljs.core/deref",1901963335,null);mB=new $CLJS.N(null,"block","block",664686210);nea=new $CLJS.w(null,"when","when",1064114221,null);AC=new $CLJS.N(null,"else-params","else-params",-832171646);$CLJS.lD=new $CLJS.N(null,"count","count",2139924085);$CLJS.PC=new $CLJS.N(null,"right","right",-452581833);kB=new $CLJS.N(null,"linear","linear",872268697);oea=new $CLJS.w(null,"when-not","when-not",-1223136340,null);
PB=new $CLJS.N(null,"padchar","padchar",2018584530);NB=new $CLJS.N(null,"mincol","mincol",1230695445);pea=new $CLJS.N(null,"not-delivered","not-delivered",1599158697);vA=new $CLJS.N(null,"miser-width","miser-width",-1310049437);qea=new $CLJS.w(null,"with-local-vars","with-local-vars",837642072,null);Tda=new $CLJS.w(null,"lift-ns","lift-ns",602311926,null);TC=new $CLJS.N(null,"allows-separator","allows-separator",-818967742);IA=new $CLJS.N(null,"buffering","buffering",-876713613);
mD=new $CLJS.N(null,"arg1","arg1",951899358);hC=new $CLJS.N(null,"base-args","base-args",-1268706822);nD=new $CLJS.N(null,"arg3","arg3",-1486822496);oD=new $CLJS.N(null,"arg2","arg2",1729550917);TB=new $CLJS.N(null,"commainterval","commainterval",-1980061083);rea=new $CLJS.N(null,"right-margin","right-margin",-810413306);KA=new $CLJS.N(null,"buffer-blob","buffer-blob",-1830112173);sea=new $CLJS.w(null,"with-open","with-open",172119667,null);
Sda=new $CLJS.w("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);tea=new $CLJS.N(null,"lines","lines",-700165781);oB=new $CLJS.N(null,"indent-t","indent-t",528318969);yC=new $CLJS.N(null,"right-params","right-params",-1790676237);EB=new $CLJS.N(null,"colon","colon",-965200945);uea=new $CLJS.w(null,"if-not","if-not",-265415609,null);iC=new $CLJS.N(null,"func","func",-238706040);$CLJS.pD=new $CLJS.N(null,"last","last",1105735132);$C=new $CLJS.N(null,"deref","deref",-145586795);
vea=new $CLJS.w(null,"dotimes","dotimes",-818708397,null);xC=new $CLJS.N(null,"max-iterations","max-iterations",2021275563);wea=new $CLJS.w(null,"cond","cond",1606708055,null);MB=new $CLJS.N(null,"minpad","minpad",323570901);yA=new $CLJS.N(null,"logical-block","logical-block",-581022564);xea=new $CLJS.w(null,"struct-map","struct-map",-1387540878,null);YA=new $CLJS.N(null,"end-block-t","end-block-t",1544648735);qD=new $CLJS.N(null,"stream","stream",1534941648);
gC=new $CLJS.N(null,"params","params",710516235);yea=new $CLJS.N(null,"circle","circle",1903212362);rA=new $CLJS.N(null,"start-pos","start-pos",668789086);HC=new $CLJS.N(null,"flags","flags",1775418075);jB=new $CLJS.N(null,"fill","fill",883462889);cda=new $CLJS.N(null,"buffer-block","buffer-block",-10937307);KC=new $CLJS.N(null,"generator-fn","generator-fn",811851656);wA=new $CLJS.N(null,"start-col","start-col",668080143);nC=new $CLJS.N(null,"w","w",354169001);$CLJS.uC=new $CLJS.N(null,"n","n",562130025);
NC=new $CLJS.N(null,"def","def",-1043430536);rC=new $CLJS.N(null,"e","e",1381269198);oC=new $CLJS.N(null,"d","d",1972142424);pC=new $CLJS.N(null,"overflowchar","overflowchar",-1620088106);BC=new $CLJS.N(null,"min-remaining","min-remaining",962687677);UB=new $CLJS.N(null,"commachar","commachar",652859327);rD=new $CLJS.N(null,"section","section",-300141526);sD=new $CLJS.N(null,"pretty","pretty",-1916372486);zea=new $CLJS.w(null,"let","let",358118826,null);
$CLJS.wC=new $CLJS.N(null,"clauses","clauses",1454841241);Aea=new $CLJS.w(null,"defonce","defonce",-1681484013,null);cA=new $CLJS.N(null,"base","base",185279322);lA=new $CLJS.N(null,"type-tag","type-tag",-1873863267);Bea=new $CLJS.w(null,"locking","locking",1542862874,null);JA=new $CLJS.N(null,"pos","pos",-864607220);Cea=new $CLJS.N(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.bA=new $CLJS.N(null,"cur","cur",1153190599);var QB=function QB(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return QB.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)},cB,PA,uD,vD,wD,KB,JB,fB,aB,xD;QB.l=function(a){return $CLJS.Qc($CLJS.Sz,$CLJS.R.h($CLJS.VB,a))};QB.v=0;QB.B=function(a){return this.l($CLJS.A(a))};var Rz=function Rz(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Rz.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
Rz.l=function(a){return $CLJS.Qc($CLJS.Sz,$CLJS.R.h($CLJS.aj,a))};Rz.v=0;Rz.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=dA.prototype;$CLJS.h.O=function(a,b){return new dA(this.La,this.Bg,this.Hd,b)};$CLJS.h.N=function(){return this.Vi};$CLJS.h.$b=function(){return this.Hd};$CLJS.h.Sc=function(){return Lz(this.La)};
$CLJS.h.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?aA(this,$CLJS.bA,$CLJS.Zz(this,$CLJS.bA)+$CLJS.E(b)):(aA(this,$CLJS.bA,$CLJS.E(b)-a-1),aA(this,$CLJS.Xk,$CLJS.Zz(this,$CLJS.Xk)+$CLJS.E($CLJS.ug(function(c){return $CLJS.F.h(c,"\n")},b)))),$CLJS.Qc($CLJS.Zz(this,cA),b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return Zca(this,b);throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));
};$CLJS.h=eA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "parent":return this.parent;case "section":return this.Mb;case "start-col":return this.Eb;case "indent":return this.Bb;case "done-nl":return this.Gb;case "intra-block-nl":return this.Hb;case "prefix":return this.prefix;case "per-line-prefix":return this.Lb;case "suffix":return this.Nb;case "logical-block-callback":return this.Ib;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cv,this.parent],null),new $CLJS.S(null,2,5,$CLJS.T,[rD,this.Mb],null),new $CLJS.S(null,2,5,$CLJS.T,[wA,this.Eb],null),new $CLJS.S(null,2,5,$CLJS.T,[AA,this.Bb],null),new $CLJS.S(null,2,5,$CLJS.T,[tA,this.Gb],null),new $CLJS.S(null,2,5,$CLJS.T,[BA,this.Hb],null),new $CLJS.S(null,2,5,
$CLJS.T,[kD,this.prefix],null),new $CLJS.S(null,2,5,$CLJS.T,[zA,this.Lb],null),new $CLJS.S(null,2,5,$CLJS.T,[WA,this.Nb],null),new $CLJS.S(null,2,5,$CLJS.T,[SA,this.Ib],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,10,new $CLJS.S(null,10,5,$CLJS.T,[$CLJS.cv,rD,wA,AA,tA,BA,kD,zA,WA,SA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 10+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1977012399^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.parent,b.parent)&&$CLJS.F.h(this.Mb,b.Mb)&&$CLJS.F.h(this.Eb,b.Eb)&&$CLJS.F.h(this.Bb,b.Bb)&&$CLJS.F.h(this.Gb,b.Gb)&&$CLJS.F.h(this.Hb,b.Hb)&&$CLJS.F.h(this.prefix,b.prefix)&&$CLJS.F.h(this.Lb,b.Lb)&&$CLJS.F.h(this.Nb,b.Nb)&&$CLJS.F.h(this.Ib,b.Ib)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,10,[WA,null,AA,null,$CLJS.cv,null,rD,null,tA,null,wA,null,kD,null,zA,null,SA,null,BA,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.cv,b):$CLJS.O.call(null,$CLJS.cv,b))?new eA(c,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(rD,b):$CLJS.O.call(null,rD,b))?new eA(this.parent,c,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(wA,b):$CLJS.O.call(null,wA,b))?new eA(this.parent,this.Mb,c,this.Bb,this.Gb,this.Hb,this.prefix,
this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(AA,b):$CLJS.O.call(null,AA,b))?new eA(this.parent,this.Mb,this.Eb,c,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(tA,b):$CLJS.O.call(null,tA,b))?new eA(this.parent,this.Mb,this.Eb,this.Bb,c,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(BA,b):$CLJS.O.call(null,BA,b))?new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,c,this.prefix,
this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(kD,b):$CLJS.O.call(null,kD,b))?new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,c,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(zA,b):$CLJS.O.call(null,zA,b))?new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,c,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(WA,b):$CLJS.O.call(null,WA,b))?new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,
this.Lb,c,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(SA,b):$CLJS.O.call(null,SA,b))?new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,c,this.F,this.m,null):new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.Je($CLJS.cv,this.parent),new $CLJS.Je(rD,this.Mb),new $CLJS.Je(wA,this.Eb),new $CLJS.Je(AA,this.Bb),new $CLJS.Je(tA,this.Gb),new $CLJS.Je(BA,this.Hb),new $CLJS.Je(kD,this.prefix),new $CLJS.Je(zA,this.Lb),new $CLJS.Je(WA,this.Nb),new $CLJS.Je(SA,this.Ib)],null),this.m))};$CLJS.h.O=function(a,b){return new eA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=gA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "data":return this.data;case "trailing-white-space":return this.wc;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[lA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl,this.data],null),new $CLJS.S(null,2,5,$CLJS.T,[nA,this.wc],null),new $CLJS.S(null,2,5,$CLJS.T,[rA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,5,new $CLJS.S(null,5,5,$CLJS.T,[lA,$CLJS.bl,nA,rA,qA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 5+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1809113693^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.data,b.data)&&$CLJS.F.h(this.wc,b.wc)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,5,[qA,null,nA,null,lA,null,rA,null,$CLJS.bl,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new gA(this.ja,this.data,this.wc,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(lA,b):$CLJS.O.call(null,lA,b))?new gA(c,this.data,this.wc,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.bl,b):$CLJS.O.call(null,$CLJS.bl,b))?new gA(this.ja,c,this.wc,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(nA,b):$CLJS.O.call(null,nA,b))?new gA(this.ja,this.data,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(rA,b):$CLJS.O.call(null,rA,b))?new gA(this.ja,this.data,this.wc,
c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new gA(this.ja,this.data,this.wc,this.ia,c,this.F,this.m,null):new gA(this.ja,this.data,this.wc,this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.Je(lA,this.ja),new $CLJS.Je($CLJS.bl,this.data),new $CLJS.Je(nA,this.wc),new $CLJS.Je(rA,this.ia),new $CLJS.Je(qA,this.ha)],null),this.m))};
$CLJS.h.O=function(a,b){return new gA(this.ja,this.data,this.wc,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=hA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "type":return this.type;case "logical-block":return this.la;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[lA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk,this.type],null),new $CLJS.S(null,2,5,$CLJS.T,[yA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[rA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,5,new $CLJS.S(null,5,5,$CLJS.T,[lA,$CLJS.Wk,yA,rA,qA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 5+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1640656800^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.type,b.type)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,5,[qA,null,$CLJS.Wk,null,yA,null,lA,null,rA,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new hA(this.ja,this.type,this.la,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(lA,b):$CLJS.O.call(null,lA,b))?new hA(c,this.type,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Wk,b):$CLJS.O.call(null,$CLJS.Wk,b))?new hA(this.ja,c,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(yA,b):$CLJS.O.call(null,yA,b))?new hA(this.ja,this.type,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(rA,b):$CLJS.O.call(null,rA,b))?new hA(this.ja,this.type,this.la,
c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new hA(this.ja,this.type,this.la,this.ia,c,this.F,this.m,null):new hA(this.ja,this.type,this.la,this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.Je(lA,this.ja),new $CLJS.Je($CLJS.Wk,this.type),new $CLJS.Je(yA,this.la),new $CLJS.Je(rA,this.ia),new $CLJS.Je(qA,this.ha)],null),this.m))};
$CLJS.h.O=function(a,b){return new hA(this.ja,this.type,this.la,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=iA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "logical-block":return this.la;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[lA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[yA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[rA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[lA,yA,rA,qA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-414877272^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[qA,null,yA,null,lA,null,rA,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new iA(this.ja,this.la,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(lA,b):$CLJS.O.call(null,lA,b))?new iA(c,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(yA,b):$CLJS.O.call(null,yA,b))?new iA(this.ja,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(rA,b):$CLJS.O.call(null,rA,b))?new iA(this.ja,this.la,c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new iA(this.ja,this.la,this.ia,c,this.F,this.m,null):new iA(this.ja,this.la,
this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je(lA,this.ja),new $CLJS.Je(yA,this.la),new $CLJS.Je(rA,this.ia),new $CLJS.Je(qA,this.ha)],null),this.m))};$CLJS.h.O=function(a,b){return new iA(this.ja,this.la,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=jA.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "logical-block":return this.la;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[lA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[yA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[rA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[lA,yA,rA,qA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1365867980^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[qA,null,yA,null,lA,null,rA,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new jA(this.ja,this.la,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(lA,b):$CLJS.O.call(null,lA,b))?new jA(c,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(yA,b):$CLJS.O.call(null,yA,b))?new jA(this.ja,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(rA,b):$CLJS.O.call(null,rA,b))?new jA(this.ja,this.la,c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new jA(this.ja,this.la,this.ia,c,this.F,this.m,null):new jA(this.ja,this.la,
this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je(lA,this.ja),new $CLJS.Je(yA,this.la),new $CLJS.Je(rA,this.ia),new $CLJS.Je(qA,this.ha)],null),this.m))};$CLJS.h.O=function(a,b){return new jA(this.ja,this.la,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=kA.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "logical-block":return this.la;case "relative-to":return this.lc;case "offset":return this.offset;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[lA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[yA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[hD,this.lc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xu,this.offset],null),new $CLJS.S(null,2,5,$CLJS.T,[rA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,6,new $CLJS.S(null,6,5,$CLJS.T,[lA,yA,hD,$CLJS.Xu,rA,qA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 6+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1602780238^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.lc,b.lc)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,6,[$CLJS.Xu,null,qA,null,hD,null,yA,null,lA,null,rA,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new kA(this.ja,this.la,this.lc,this.offset,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(lA,b):$CLJS.O.call(null,lA,b))?new kA(c,this.la,this.lc,this.offset,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(yA,b):$CLJS.O.call(null,yA,b))?new kA(this.ja,c,this.lc,this.offset,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(hD,b):$CLJS.O.call(null,hD,b))?new kA(this.ja,this.la,c,this.offset,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Xu,b):$CLJS.O.call(null,$CLJS.Xu,b))?new kA(this.ja,
this.la,this.lc,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(rA,b):$CLJS.O.call(null,rA,b))?new kA(this.ja,this.la,this.lc,this.offset,c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new kA(this.ja,this.la,this.lc,this.offset,this.ia,c,this.F,this.m,null):new kA(this.ja,this.la,this.lc,this.offset,this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.Je(lA,this.ja),new $CLJS.Je(yA,this.la),new $CLJS.Je(hD,this.lc),new $CLJS.Je($CLJS.Xu,this.offset),new $CLJS.Je(rA,this.ia),new $CLJS.Je(qA,this.ha)],null),this.m))};$CLJS.h.O=function(a,b){return new kA(this.ja,this.la,this.lc,this.offset,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
var oA=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("cljs.pprint","write-token"),function(f,g){return lA.g(g)},$CLJS.Pj,e,a,b,c,d)}();
oA.o(null,UA,function(a,b){var c=SA.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g($CLJS.TA):c.call(null,$CLJS.TA));b=yA.g(b);c=kD.g(b);$CLJS.m(c)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),c);a=$CLJS.Zz(cA.g($CLJS.r($CLJS.r(a))),$CLJS.bA);$CLJS.gg(wA.g(b),a);return $CLJS.gg(AA.g(b),a)});oA.o(null,YA,function(a,b){var c=SA.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g(XA):c.call(null,XA));b=WA.g(yA.g(b));return $CLJS.m(b)?$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),b):null});
oA.o(null,oB,function(a,b){var c=yA.g(b),d=AA.g(c),e=$CLJS.Xu.g(b);b=hD.g(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(mB,b):$CLJS.F.call(null,mB,b)))a=$CLJS.r(wA.g(c));else if($CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.nB,b):$CLJS.F.call(null,$CLJS.nB,b)))a=$CLJS.Zz(cA.g($CLJS.r($CLJS.r(a))),$CLJS.bA);else throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));return $CLJS.gg(d,e+a)});oA.o(null,KA,function(a,b){return $CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),$CLJS.bl.g(b))});
oA.o(null,mA,function(a,b){var c=$CLJS.F.h($CLJS.Wk.g(b),hB);c||(c=(c=!$CLJS.F.h($CLJS.Wk.g(b),jB))?$CLJS.r(tA.g(yA.g(b))):c);$CLJS.m(c)?CA.h?CA.h(a,b):CA.call(null,a,b):(b=nA.g($CLJS.r($CLJS.r(a))),$CLJS.m(b)&&$CLJS.Qc(cA.g($CLJS.r($CLJS.r(a))),b));return $CLJS.dj.D($CLJS.r(a),$CLJS.U,nA,null)});
var tD=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("cljs.pprint","emit-nl?"),function(f){return $CLJS.Wk.g(f)},$CLJS.Pj,e,a,b,c,d)}();tD.o(null,kB,function(a,b,c){a=yA.g(a);return uA(b,a,c)});tD.o(null,iB,function(a,b,c){a=yA.g(a);return xA(b,a,c)});
tD.o(null,jB,function(a,b,c,d){a=yA.g(a);var e=$CLJS.r(BA.g(a));return $CLJS.m(e)?e:(d=!sA(b,d))?d:xA(b,a,c)});tD.o(null,hB,function(){return!0});
var DA=function DA(a,b){var d=bda(b);b=$CLJS.J(d,0,null);var e=$CLJS.J(d,1,null);$CLJS.m(b)&&pA(a,b,!1);if($CLJS.m(e)){d=$ca(e);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=$CLJS.C(e),g=tD.D(f,a,b,ada(e));$CLJS.m(g)&&(CA(a,f),e=$CLJS.D(e));sA(a,e)?a=e:(e=DA.h?DA.h(a,b):DA.call(null,a,b),$CLJS.F.h(e,b)?(pA(a,b,!1),a=d):a=$CLJS.oh.h($CLJS.Lg,$CLJS.qg.h(e,d)));return a}return null};$CLJS.h=NA.prototype;$CLJS.h.O=function(a,b){return new NA(this.La,this.Bg,this.fj,this.ei,this.Hd,b)};$CLJS.h.N=function(){return this.Wi};
$CLJS.h.$b=function(){return this.Hd};
$CLJS.h.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a))){var c=MA(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.dk.g($CLJS.r($CLJS.r(this)));if($CLJS.F.h(d,LA))return GA(this),$CLJS.Qc(cA.g($CLJS.r($CLJS.r(this))),b),$CLJS.dj.D($CLJS.r(this),$CLJS.U,nA,a);d=JA.g($CLJS.r($CLJS.r(this)));c=d+$CLJS.E(c);$CLJS.dj.D($CLJS.r(this),$CLJS.U,JA,c);return FA(this,new gA(KA,b,a,d,c,null,null,null))}if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,
a):$CLJS.F.call(null,Number,a)))return $CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(this))),LA)?(GA(this),b=$CLJS.Qc(cA.g($CLJS.r($CLJS.r(this))),b)):$CLJS.F.h(b,"\n")?b=MA(this,"\n"):(a=JA.g($CLJS.r($CLJS.r(this))),c=a+1,$CLJS.dj.D($CLJS.r(this),$CLJS.U,JA,c),b=$CLJS.Gu(b),b=FA(this,new gA(KA,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};$CLJS.h.Sc=function(){this.ig(null);return Lz(cA.g($CLJS.r($CLJS.r(this))))};
$CLJS.h.ig=function(){return $CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(this))),IA)?(pA(this,$CLJS.Uu.g($CLJS.r($CLJS.r(this))),!0),$CLJS.dj.D($CLJS.r(this),$CLJS.U,$CLJS.Uu,$CLJS.Lg)):GA(this)};$CLJS.bB=!0;cB=null;$CLJS.OA=72;PA=40;uD=null;vD=null;wD=null;KB=null;JB=10;fB=0;aB=null;xD=function xD(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xD.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
xD.l=function(a,b){var c=$CLJS.gn.l($CLJS.G([new $CLJS.k(null,1,[qD,!0],null),$CLJS.R.h(gD,b)]));b=JB;var d=vD,e=$CLJS.yb,f=$CLJS.Gi,g=uD,l=PA,n=cB,q=$CLJS.bB,u=KB,v=$CLJS.ub,x=$CLJS.OA,y=wD,B=cA.h(c,JB),H=yea.h(c,vD),I=$CLJS.jD.h(c,$CLJS.yb),Q=$CLJS.$u.h(c,$CLJS.Gi),Y=tea.h(c,uD),aa=vA.h(c,PA),ha=$CLJS.wk.h(c,cB),qa=sD.h(c,$CLJS.bB),Ea=jea.h(c,KB),kb=$CLJS.tb.h(c,$CLJS.ub),lb=rea.h(c,$CLJS.OA),Fb=Cea.h(c,wD);JB=B;vD=H;$CLJS.yb=I;$CLJS.Gi=Q;uD=Y;PA=aa;cB=ha;$CLJS.bB=qa;KB=Ea;$CLJS.ub=kb;$CLJS.OA=
lb;wD=Fb;try{var Mb=new $CLJS.rb,ac=$CLJS.Ie(c,qD)?qD.g(c):!0,Tb=!0===ac||null==ac?new $CLJS.gd(Mb):ac;if($CLJS.m($CLJS.bB)){var Ub=$CLJS.Gb($CLJS.$A(Tb));c=$CLJS.Sz;$CLJS.Sz=Ub?$CLJS.RA(Tb):Tb;try{$CLJS.dB(a),$CLJS.Yz()}finally{$CLJS.Sz=c}}else{Ub=$CLJS.Sz;$CLJS.Sz=Tb;try{Rz.call(null,a)}finally{$CLJS.Sz=Ub}}!0===ac&&$CLJS.Oz($CLJS.p.g(Mb));return null==ac?$CLJS.p.g(Mb):null}finally{wD=y,$CLJS.OA=x,$CLJS.ub=v,KB=u,$CLJS.bB=q,cB=n,PA=l,uD=g,$CLJS.Gi=f,$CLJS.yb=e,vD=d,JB=b}};xD.v=1;
xD.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var vB=null;$CLJS.h=xB.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "seq":return this.Wc;case "rest":return this.xb;case "pos":return this.uc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[yB,this.Wc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,this.xb],null),new $CLJS.S(null,2,5,$CLJS.T,[JA,this.uc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,3,new $CLJS.S(null,3,5,$CLJS.T,[yB,$CLJS.xk,JA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};
$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 3+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-402038447^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Wc,b.Wc)&&$CLJS.F.h(this.xb,b.xb)&&$CLJS.F.h(this.uc,b.uc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,3,[JA,null,yB,null,$CLJS.xk,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new xB(this.Wc,this.xb,this.uc,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(yB,b):$CLJS.O.call(null,yB,b))?new xB(c,this.xb,this.uc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.xk,b):$CLJS.O.call(null,$CLJS.xk,b))?new xB(this.Wc,c,this.uc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(JA,b):$CLJS.O.call(null,JA,b))?new xB(this.Wc,this.xb,c,this.F,this.m,null):new xB(this.Wc,this.xb,this.uc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.Je(yB,this.Wc),new $CLJS.Je($CLJS.xk,this.xb),new $CLJS.Je(JA,this.uc)],null),this.m))};$CLJS.h.O=function(a,b){return new xB(this.Wc,this.xb,this.uc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=DB.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "func":return this.Ac;case "def":return this.yc;case "params":return this.Hc;case "offset":return this.offset;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[iC,this.Ac],null),new $CLJS.S(null,2,5,$CLJS.T,[NC,this.yc],null),new $CLJS.S(null,2,5,$CLJS.T,[gC,this.Hc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xu,this.offset],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[iC,NC,gC,$CLJS.Xu],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-829256337^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Ac,b.Ac)&&$CLJS.F.h(this.yc,b.yc)&&$CLJS.F.h(this.Hc,b.Hc)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.Xu,null,iC,null,gC,null,NC,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new DB(this.Ac,this.yc,this.Hc,this.offset,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(iC,b):$CLJS.O.call(null,iC,b))?new DB(c,this.yc,this.Hc,this.offset,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(NC,b):$CLJS.O.call(null,NC,b))?new DB(this.Ac,c,this.Hc,this.offset,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(gC,b):$CLJS.O.call(null,gC,b))?new DB(this.Ac,this.yc,c,this.offset,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Xu,b):$CLJS.O.call(null,$CLJS.Xu,b))?new DB(this.Ac,this.yc,this.Hc,c,this.F,this.m,null):
new DB(this.Ac,this.yc,this.Hc,this.offset,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je(iC,this.Ac),new $CLJS.Je(NC,this.yc),new $CLJS.Je(gC,this.Hc),new $CLJS.Je($CLJS.Xu,this.offset)],null),this.m))};$CLJS.h.O=function(a,b){return new DB(this.Ac,this.yc,this.Hc,this.offset,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
var gda=new $CLJS.k(null,3,[2,"#b",8,"#o",16,"#x"],null),XB=new $CLJS.S(null,20,5,$CLJS.T,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),yD=new $CLJS.S(null,20,5,$CLJS.T,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),YB=new $CLJS.S(null,10,5,$CLJS.T,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),Dea=new $CLJS.S(null,10,5,$CLJS.T,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),$B=new $CLJS.S(null,22,5,$CLJS.T," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),Eea=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,9,5,$CLJS.T,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.S(null,3,5,$CLJS.T,["M","MM","MMM"],null)],null),Fea=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,9,5,$CLJS.T,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.S(null,3,5,$CLJS.T,["M","MM","MMM"],null)],null),ida=new $CLJS.k(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);DC.prototype.O=function(a,b){return new DC(this.La,b)};DC.prototype.N=function(){return this.Yi};DC.prototype.Sc=function(){return Lz(this.La)};
DC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.La,b.toLowerCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Qc(this.La,$CLJS.Gu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};EC.prototype.O=function(a,b){return new EC(this.La,b)};EC.prototype.N=function(){return this.Zi};EC.prototype.Sc=function(){return Lz(this.La)};
EC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.La,b.toUpperCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Qc(this.La,$CLJS.Gu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};FC.prototype.O=function(a,b){return new FC(this.La,this.we,b)};FC.prototype.N=function(){return this.$i};FC.prototype.Sc=function(){return Lz(this.La)};
FC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.La,Ada(b.toLowerCase(),$CLJS.r(this.we))),0<b.length?$CLJS.gg(this.we,$CLJS.Sa($CLJS.Td(b,$CLJS.E(b)-1))):null;if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=$CLJS.Gu(b),a=$CLJS.m($CLJS.r(this.we))?b.toUpperCase():b,$CLJS.Qc(this.La,a),$CLJS.gg(this.we,$CLJS.Sa(b));throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
GC.prototype.O=function(a,b){return new GC(this.La,this.ke,b)};GC.prototype.N=function(){return this.aj};GC.prototype.Sc=function(){return Lz(this.La)};
GC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Gb($CLJS.r(this.ke))?(a=RegExp("\\S","g").exec(b),a=$CLJS.m(a)?a.index:a,$CLJS.m(a)?($CLJS.Qc(this.La,[b.substring(0,a),$CLJS.Td(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.gg(this.ke,!0)):$CLJS.Qc(this.La,b)):$CLJS.Qc(this.La,b.toLowerCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=$CLJS.Gu(b),
a=$CLJS.Gb($CLJS.r(this.ke)),$CLJS.m(a?Jz(b):a)?($CLJS.gg(this.ke,!0),$CLJS.Qc(this.La,b.toUpperCase())):$CLJS.Qc(this.La,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
var Nda=$CLJS.Sh("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.k(null,5,[IC,"A",gC,new $CLJS.k(null,4,[NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){return RB($CLJS.VB,a,b)}}],null),new $CLJS.k(null,
5,[IC,"S",gC,new $CLJS.k(null,4,[NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){return RB($CLJS.aj,a,b)}}],null),new $CLJS.k(null,5,[IC,"D",gC,new $CLJS.k(null,4,[NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,
2,5,$CLJS.T,[" ",String],null),UB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){return WB(10,a,b)}}],null),new $CLJS.k(null,5,[IC,"B",gC,new $CLJS.k(null,4,[NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),UB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),TB,new $CLJS.S(null,
2,5,$CLJS.T,[3,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){return WB(2,a,b)}}],null),new $CLJS.k(null,5,[IC,"O",gC,new $CLJS.k(null,4,[NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),UB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,
null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){return WB(8,a,b)}}],null),new $CLJS.k(null,5,[IC,"X",gC,new $CLJS.k(null,4,[NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),UB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){return WB(16,a,b)}}],null),new $CLJS.k(null,
5,[IC,"R",gC,new $CLJS.k(null,5,[cA,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),UB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(a){return $CLJS.m($CLJS.C(cA.g(a)))?function(b,c){return WB(cA.g(b),b,c)}:$CLJS.m(function(){var b=FB.g(a);return $CLJS.m(b)?
EB.g(a):b}())?function(b,c){return bC(Eea,c)}:$CLJS.m(FB.g(a))?function(b,c){return bC(Fea,c)}:$CLJS.m(EB.g(a))?function(b,c){b=zB(c);c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);if($CLJS.F.h(0,c))QB.l($CLJS.G(["zeroth"]));else{var d=SB(1E3,0>c?-c:c);if($CLJS.E(d)<=$CLJS.E($B)){var e=$CLJS.rg.h(ZB,$CLJS.Nu(1,d));e=aC(e,1);var f=$CLJS.$d(d);d=$CLJS.af(f,100);f=Nz(f,100);var g=0<d?[$CLJS.p.g($CLJS.Td(XB,d))," hundred"].join(""):null,l=$CLJS.p,n=l.g;if(0<f)if(20>f)var q=$CLJS.Td(yD,f);else{q=$CLJS.af(f,10);
var u=Nz(f,10);q=0<q&&!(0<u)?$CLJS.Td(Dea,q):[$CLJS.p.g(0<q?$CLJS.Td(YB,q):null),0<q&&0<u?"-":null,$CLJS.p.g(0<u?$CLJS.Td(yD,u):null)].join("")}else q=0<d?"th":null;d=[g,0<d&&0<f?" ":null,n.call(l,q)].join("");QB.l($CLJS.G([[0>c?"minus ":null,$CLJS.je(e)||$CLJS.je(d)?$CLJS.je(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else WB(10,new $CLJS.k(null,5,[NB,0,PB," ",UB,",",TB,3,EB,!0],null),rB(new $CLJS.S(null,1,5,$CLJS.T,[c],null))),e=Nz(c,100),c=11<e||19>e,e=Nz(e,10),QB.l($CLJS.G([1===e&&
c?"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=zB(c);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.F.h(0,b))QB.l($CLJS.G(["zero"]));else{var d=SB(1E3,0>b?-b:b);$CLJS.E(d)<=$CLJS.E($B)?(d=$CLJS.rg.h(ZB,d),d=aC(d,0),QB.l($CLJS.G([[0>b?"minus ":null,d].join("")]))):WB(10,new $CLJS.k(null,5,[NB,0,PB," ",UB,",",TB,3,EB,!0],null),rB(new $CLJS.S(null,1,5,$CLJS.T,[b],null)))}return c}}],null),new $CLJS.k(null,5,[IC,"P",gC,$CLJS.P,HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,
null,JC,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){b=$CLJS.m(EB.g(a))?BB(b,-1):b;a=$CLJS.m(FB.g(a))?new $CLJS.S(null,2,5,$CLJS.T,["y","ies"],null):new $CLJS.S(null,2,5,$CLJS.T,["","s"],null);var c=zB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);QB.l($CLJS.G([$CLJS.F.h(b,1)?$CLJS.C(a):$CLJS.Zd(a)]));return c}}],null),new $CLJS.k(null,5,[IC,"C",gC,new $CLJS.k(null,1,[cC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,
JC,null],null),null),MC,$CLJS.P,KC,function(a){return $CLJS.m(EB.g(a))?jda:$CLJS.m(FB.g(a))?kda:lda}],null),new $CLJS.k(null,5,[IC,"F",gC,new $CLJS.k(null,5,[nC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),oC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.av,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),pC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,1,[FB,null],null),null),MC,$CLJS.P,KC,
function(){return qC}],null),new $CLJS.k(null,5,[IC,"E",gC,new $CLJS.k(null,7,[nC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),oC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),rC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.av,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),pC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),sC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,1,[FB,null],
null),null),MC,$CLJS.P,KC,function(){return tC}],null),new $CLJS.k(null,5,[IC,"G",gC,new $CLJS.k(null,7,[nC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),oC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),rC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.av,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),pC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),sC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,
1,[FB,null],null),null),MC,$CLJS.P,KC,function(){return nda}],null),new $CLJS.k(null,5,[IC,"$",gC,new $CLJS.k(null,4,[oC,new $CLJS.S(null,2,5,$CLJS.T,[2,Number],null),$CLJS.uC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),nC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(){return oda}],null),new $CLJS.k(null,5,[IC,"%",gC,new $CLJS.k(null,1,[$CLJS.lD,
new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),HC,$CLJS.oi,MC,$CLJS.P,KC,function(){return function(a,b){a=$CLJS.lD.g(a);for(var c=0;;)if(c<a)Tz(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[IC,"\x26",gC,new $CLJS.k(null,1,[$CLJS.lD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,1,[sD,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){a=$CLJS.lD.g(a);0<a&&((null!=$CLJS.Sz?$CLJS.Sz.C&32768||$CLJS.t===$CLJS.Sz.Zf||($CLJS.Sz.C?
0:$CLJS.Nb($CLJS.Kz,$CLJS.Sz)):$CLJS.Nb($CLJS.Kz,$CLJS.Sz))?$CLJS.F.h(0,$CLJS.Zz(cA.g($CLJS.r($CLJS.r($CLJS.Sz))),$CLJS.bA))||Tz():Tz());--a;for(var c=0;;)if(c<a)Tz(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[IC,"|",gC,new $CLJS.k(null,1,[$CLJS.lD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),HC,$CLJS.oi,MC,$CLJS.P,KC,function(){return function(a,b){a=$CLJS.lD.g(a);for(var c=0;;)if(c<a)QB.l($CLJS.G(["\f"])),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[IC,"~",gC,new $CLJS.k(null,
1,[$CLJS.uC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),HC,$CLJS.oi,MC,$CLJS.P,KC,function(){return function(a,b){a=$CLJS.uC.g(a);QB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(a,"~"))]));return b}}],null),new $CLJS.k(null,5,[IC,"\n",gC,$CLJS.P,HC,new $CLJS.ni(null,new $CLJS.k(null,2,[EB,null,FB,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){$CLJS.m(FB.g(a))&&Tz();return b}}],null),new $CLJS.k(null,5,[IC,"T",gC,new $CLJS.k(null,2,[iD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),
OB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,2,[FB,null,sD,null],null),null),MC,$CLJS.P,KC,function(a){return $CLJS.m(FB.g(a))?function(b,c){var d=iD.g(b);b=OB.g(b);var e=d+$CLJS.Zz(cA.g($CLJS.r($CLJS.r($CLJS.Sz))),$CLJS.bA);e=0<b?Nz(e,b):0;d+=$CLJS.F.h(0,e)?0:b-e;QB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(d," "))]));return c}:function(b,c){var d=iD.g(b);b=OB.g(b);var e=$CLJS.Zz(cA.g($CLJS.r($CLJS.r($CLJS.Sz))),$CLJS.bA);d=e<d?d-e:$CLJS.F.h(b,0)?0:b-
Nz(e-d,b);QB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(d," "))]));return c}}],null),new $CLJS.k(null,5,[IC,"*",gC,new $CLJS.k(null,1,[$CLJS.uC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,2,[EB,null,FB,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){var c=$CLJS.uC.g(a);return $CLJS.m(FB.g(a))?CB(b,c):BB(b,$CLJS.m(EB.g(a))?-c:c)}}],null),new $CLJS.k(null,5,[IC,"?",gC,$CLJS.P,HC,new $CLJS.ni(null,new $CLJS.k(null,1,[FB,null],null),null),MC,$CLJS.P,
KC,function(a){return $CLJS.m(FB.g(a))?function(b,c){var d=AB(c);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return jC(c,d,hC.g(b))}:function(b,c){var d=AB(c);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var e=zB(d);d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);d=rB(d);jC(c,d,hC.g(b));return e}}],null),new $CLJS.k(null,5,[IC,"(",gC,$CLJS.P,HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,new $CLJS.k(null,3,[$CLJS.PC,")",TC,null,$CLJS.Vu,null],null),KC,function(a){var b=$CLJS.m(function(){var c=
FB.g(a);return $CLJS.m(c)?EB.g(a):c}())?zda:$CLJS.m(EB.g(a))?Bda:$CLJS.m(FB.g(a))?Cda:yda;return function(c,d){a:{var e=$CLJS.C($CLJS.wC.g(c)),f=$CLJS.Sz;$CLJS.Sz=b.g?b.g($CLJS.Sz):b.call(null,$CLJS.Sz);try{var g=jC(e,d,hC.g(c));break a}finally{$CLJS.Sz=f}g=void 0}return g}}],null),new $CLJS.k(null,5,[IC,")",gC,$CLJS.P,HC,$CLJS.oi,MC,$CLJS.P,KC,function(){return null}],null),new $CLJS.k(null,5,[IC,"[",gC,new $CLJS.k(null,1,[vC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),HC,new $CLJS.ni(null,
new $CLJS.k(null,2,[EB,null,FB,null],null),null),MC,new $CLJS.k(null,3,[$CLJS.PC,"]",TC,!0,$CLJS.Vu,$CLJS.pD],null),KC,function(a){return $CLJS.m(EB.g(a))?qda:$CLJS.m(FB.g(a))?rda:pda}],null),new $CLJS.k(null,5,[IC,";",gC,new $CLJS.k(null,2,[BC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),CC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,1,[EB,null],null),null),MC,new $CLJS.k(null,1,[$CLJS.Zu,!0],null),KC,function(){return null}],null),new $CLJS.k(null,
5,[IC,"]",gC,$CLJS.P,HC,$CLJS.oi,MC,$CLJS.P,KC,function(){return null}],null),new $CLJS.k(null,5,[IC,"{",gC,new $CLJS.k(null,1,[xC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,new $CLJS.k(null,2,[$CLJS.PC,"}",TC,!1],null),KC,function(a){var b=FB.g(a);b=$CLJS.m(b)?EB.g(a):b;return $CLJS.m(b)?vda:$CLJS.m(EB.g(a))?tda:$CLJS.m(FB.g(a))?uda:sda}],null),new $CLJS.k(null,5,[IC,"}",gC,$CLJS.P,HC,new $CLJS.ni(null,
new $CLJS.k(null,1,[EB,null],null),null),MC,$CLJS.P,KC,function(){return null}],null),new $CLJS.k(null,5,[IC,"\x3c",gC,new $CLJS.k(null,4,[NB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),PB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,4,[EB,null,FB,null,JC,null,sD,null],null),null),MC,new $CLJS.k(null,3,[$CLJS.PC,"\x3e",TC,!0,$CLJS.Vu,SC],null),KC,function(){return xda}],
null),new $CLJS.k(null,5,[IC,"\x3e",gC,$CLJS.P,HC,new $CLJS.ni(null,new $CLJS.k(null,1,[EB,null],null),null),MC,$CLJS.P,KC,function(){return null}],null),new $CLJS.k(null,5,[IC,"^",gC,new $CLJS.k(null,3,[mD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),oD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),nD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,1,[EB,null],null),null),MC,$CLJS.P,KC,function(){return function(a,b){var c=mD.g(a),d=oD.g(a),e=
nD.g(a),f=$CLJS.m(EB.g(a))?eC:dC;return $CLJS.m($CLJS.m(c)?$CLJS.m(d)?e:d:c)?c<=d&&d<=e?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:$CLJS.m($CLJS.m(c)?d:c)?$CLJS.F.h(c,d)?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:$CLJS.m(c)?$CLJS.F.h(c,0)?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:($CLJS.m(EB.g(a))?$CLJS.je($CLJS.xk.g(hC.g(a))):$CLJS.je($CLJS.xk.g(b)))?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b}}],null),new $CLJS.k(null,5,[IC,"W",gC,$CLJS.P,HC,new $CLJS.ni(null,new $CLJS.k(null,4,[EB,null,FB,null,
JC,null,sD,null],null),null),MC,$CLJS.P,KC,function(a){if($CLJS.m(function(){var c=FB.g(a);return $CLJS.m(c)?c:EB.g(a)}())){var b=$CLJS.qg.h($CLJS.m(FB.g(a))?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.$u,null,$CLJS.jD,null],null):$CLJS.Lg,$CLJS.m(EB.g(a))?new $CLJS.S(null,2,5,$CLJS.T,[sD,!0],null):$CLJS.Lg);return function(c,d){d=zB(d);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.m($CLJS.R.j(xD,c,b))?new $CLJS.S(null,2,5,$CLJS.T,[dC,d],null):d}}return function(c,d){d=zB(d);c=$CLJS.J(d,0,null);d=
$CLJS.J(d,1,null);return $CLJS.m($CLJS.dB(c))?new $CLJS.S(null,2,5,$CLJS.T,[dC,d],null):d}}],null),new $CLJS.k(null,5,[IC,"_",gC,$CLJS.P,HC,new $CLJS.ni(null,new $CLJS.k(null,3,[EB,null,FB,null,JC,null],null),null),MC,$CLJS.P,KC,function(){return Eda}],null),new $CLJS.k(null,5,[IC,"I",gC,new $CLJS.k(null,1,[$CLJS.uC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null)],null),HC,new $CLJS.ni(null,new $CLJS.k(null,1,[EB,null],null),null),MC,$CLJS.P,KC,function(){return Dda}],null)]),Fda=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
Lda=new $CLJS.ni(null,new $CLJS.k(null,2,[HB,null,GB,null],null),null),Ida=new $CLJS.k(null,2,[":",EB,"@",FB],null),sB=function sB(a){for(;;){if($CLJS.je(a))return!1;var c=sD.g(HC.g(NC.g($CLJS.C(a))));$CLJS.m(c)||(c=$CLJS.Xf(sB,$CLJS.C($CLJS.wC.g(gC.g($CLJS.C(a))))),c=$CLJS.m(c)?c:$CLJS.Xf(sB,$CLJS.C($CLJS.Vu.g(gC.g($CLJS.C(a))))));if($CLJS.m(c))return!0;a=$CLJS.D(a)}},cD=$CLJS.ej(qB),UC=new $CLJS.k(null,6,[$CLJS.Gl,"'",$CLJS.Kl,"#'",$CLJS.fba,"@",$CLJS.gba,"~",mea,"@",kea,"~"],null);
(function(){var a=cD("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=rB(d);return tB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()})();
var zD=function(){var a=cD("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=rB(d);return tB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),AD=new $CLJS.k(null,2,["core$future_call","Future","core$promise","Promise"],null),Gea=function(){var a=cD("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=rB(d);return tB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),BD,Hea=$CLJS.cj.g($CLJS.P),Iea=$CLJS.cj.g($CLJS.P),Jea=$CLJS.cj.g($CLJS.P),Kea=$CLJS.cj.g($CLJS.P),Lea=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
BD=new $CLJS.vj($CLJS.Ui.h("cljs.pprint","simple-dispatch"),aD,$CLJS.Pj,Lea,Hea,Iea,Jea,Kea);Pz(BD,$CLJS.ql,function(a){if($CLJS.Gb(VC(a)))if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var b=fB,c=aB;fB+=1;aB=0;try{VA("(",")");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e&&($CLJS.dB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.Sz," ");lB(kB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.Sz,"...");break}ZA()}finally{aB=c,fB=b}}return null});Pz(BD,$CLJS.Al,WC);
Pz(BD,$CLJS.Yl,XC);Pz(BD,$CLJS.Hk,zD);Pz(BD,null,function(){return $CLJS.Qc($CLJS.Sz,$CLJS.aj.l($CLJS.G([null])))});Pz(BD,$CLJS.Pj,YC);cB=BD;
var CD=function(){var a=cD("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=rB(d);return tB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),DD=function(){var a=cD("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=rB(d);return tB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),ED=$CLJS.P,Mea=function(a){return $CLJS.oh.h($CLJS.P,$CLJS.sg($CLJS.Ye,$CLJS.G([function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=new $CLJS.S(null,
2,5,$CLJS.T,[q,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.ui($CLJS.C(q))),$CLJS.Zd(q)],null)],null);l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.ui($CLJS.C(l))),$CLJS.Zd(l)],null)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.oh.h($CLJS.P,$CLJS.rg.h(function(b){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,
1,null);var e=$CLJS.qf(c);e=$CLJS.m(e)?e:$CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,24,[$CLJS.Ik,"null",$CLJS.$k,"null",$CLJS.gm,"null",$CLJS.lm,"null",$CLJS.Vk,"null",$CLJS.Cl,"null",$CLJS.xl,"null",$CLJS.Dl,"null",$CLJS.Vj,"null",$CLJS.Nl,"null",$CLJS.pl,"null",$CLJS.dl,"null",$CLJS.Ul,"null",$CLJS.jm,"null",$CLJS.zk,"null",$CLJS.uk,"null",$CLJS.Sk,"null",$CLJS.hl,"null",$CLJS.Jk,"null",$CLJS.Kl,"null",$CLJS.Gl,"null",$CLJS.$l,"null",$CLJS.rk,"null",$CLJS.Xl,"null"],null),null),c);return $CLJS.Gb(e)?
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.h("clojure.core",$CLJS.ui(c)),d],null):b},a))}($CLJS.Sh([$CLJS.Jk,$CLJS.uk,cea,$CLJS.Nl,hea,$da,bea,iea,fea,eea,gea,uea,oea,$CLJS.Xl,nea,sea,qea,Aea,Yda,$CLJS.dl,vea,wea,zea,$CLJS.Hl,lea,Bea,Zda,aea,dea,xea],[CD,function(a){var b=$CLJS.Zd(a),c=$CLJS.C($CLJS.zd($CLJS.zd(a)));if($CLJS.qe(b)){a=ED;ED=$CLJS.F.h(1,$CLJS.E(b))?$CLJS.Pf([$CLJS.C(b),"%"]):$CLJS.oh.h($CLJS.P,$CLJS.rg.j(function(d,e){return new $CLJS.S(null,2,5,$CLJS.T,[d,["%",$CLJS.p.g(e)].join("")],
null)},b,$CLJS.Ai(1,$CLJS.E(b)+1)));try{return function(){var d=cD("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(g){var l=null;if(0<arguments.length){l=0;for(var n=Array(arguments.length-0);l<n.length;)n[l]=arguments[l+0],++l;l=new $CLJS.z(n,0,null)}return f.call(this,l)}function f(g){g=rB(g);return tB(d,g)}e.v=0;e.B=function(g){g=$CLJS.A(g);return f(g)};e.l=f;return e}()}()(c)}finally{ED=a}}else return dD(a)},fD,DD,function(a){if(3<$CLJS.E(a)){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,
"#");else{var b=fB,c=aB;fB+=1;aB=0;try{VA("(",")");pB(mB,1);$CLJS.R.h(function(){var l=cD("~w ~@_~w ~@_~w ~_");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=rB(u);return tB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}(),a);for(var d=0,e=$CLJS.A($CLJS.kg(3,a));;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,
"#");else{a=fB;var f=aB;fB+=1;aB=0;try{VA(null,null),$CLJS.dB($CLJS.C(e)),$CLJS.D(e)&&($CLJS.Qc($CLJS.Sz," "),lB(iB),$CLJS.dB($CLJS.Zd(e))),ZA()}finally{aB=f,fB=a}}if($CLJS.D($CLJS.zd(e))){$CLJS.Qc($CLJS.Sz," ");lB(kB);a=d+1;var g=$CLJS.D($CLJS.zd(e));d=a;e=g;continue}}}else $CLJS.Qc($CLJS.Sz,"...");break}ZA()}finally{aB=c,fB=b}}return null}return dD(a)},CD,eD,eD,fD,CD,fD,DD,DD,CD,DD,fD,fD,CD,fD,function(a){if($CLJS.D(a)){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=
"string"===typeof $CLJS.C(c)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(c),$CLJS.D(c)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,c],null);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.oe($CLJS.C(d))?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(d),$CLJS.D(d)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{d=fB;var g=aB;fB+=1;aB=0;try{VA("(",")");(function(){var n=cD("~w ~1I~@_~w");return function(){function q(v){var x=
null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.z(y,0,null)}return u.call(this,x)}function u(v){v=rB(v);return tB(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};q.l=u;return q}()})()(a,b);$CLJS.m($CLJS.m(c)?c:$CLJS.m(e)?e:$CLJS.A(f))&&function(){var n=cD("~@:_");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.z(y,0,null)}return u.call(this,
x)}function u(v){v=rB(v);return tB(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};q.l=u;return q}()}()();$CLJS.m(c)&&uB(!0,'"~a"~:[~;~:@_~]',$CLJS.G([c,$CLJS.m(e)?e:$CLJS.A(f)]));$CLJS.m(e)&&function(){var n=cD("~w~:[~;~:@_~]");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.z(y,0,null)}return u.call(this,x)}function u(v){v=rB(v);return tB(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};
q.l=u;return q}()}()(e,$CLJS.A(f));for(a=f;;){Uda($CLJS.C(a));var l=$CLJS.D(a);if(l)b=l,lB(kB),a=b;else break}ZA()}finally{aB=g,fB=d}}return null}return $CLJS.dB(a)},fD,function(a){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{var b=fB,c=aB;fB+=1;aB=0;try{VA("(",")");pB(mB,1);$CLJS.dB($CLJS.C(a));if($CLJS.D(a)){$CLJS.Qc($CLJS.Sz," ");lB(kB);for(var d=0,e=$CLJS.D(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e){if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{a=fB;var f=aB;fB+=1;aB=0;try{VA(null,null),$CLJS.dB($CLJS.C(e)),
$CLJS.D(e)&&($CLJS.Qc($CLJS.Sz," "),lB(iB),$CLJS.dB($CLJS.Zd(e))),ZA()}finally{aB=f,fB=a}}if($CLJS.D($CLJS.zd(e))){$CLJS.Qc($CLJS.Sz," ");lB(kB);a=d+1;var g=$CLJS.D($CLJS.zd(e));d=a;e=g;continue}}}else $CLJS.Qc($CLJS.Sz,"...");break}}ZA()}finally{aB=c,fB=b}}return null},fD,eD,eD,CD,CD,fD,fD,CD]))),FD,Nea=$CLJS.cj.g($CLJS.P),Oea=$CLJS.cj.g($CLJS.P),Pea=$CLJS.cj.g($CLJS.P),Qea=$CLJS.cj.g($CLJS.P),Rea=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
FD=new $CLJS.vj($CLJS.Ui.h("cljs.pprint","code-dispatch"),aD,$CLJS.Pj,Rea,Nea,Oea,Pea,Qea);Pz(FD,$CLJS.ql,function(a){if($CLJS.Gb(VC(a))){var b=Mea.call(null,$CLJS.C(a));return $CLJS.m(b)?b.g?b.g(a):b.call(null,a):dD(a)}return null});Pz(FD,$CLJS.nk,function(a){var b=a.g?a.g(ED):a.call(null,ED);return $CLJS.m(b)?QB.l($CLJS.G([b])):$CLJS.m(wD)?QB.l($CLJS.G([$CLJS.ui(a)])):Rz.call(null,a)});Pz(FD,$CLJS.Al,WC);Pz(FD,$CLJS.Yl,XC);Pz(FD,$CLJS.Hk,zD);Pz(FD,ZC,Gea);
Pz(FD,$C,function(a){var b=$CLJS.p,c=b.g,d=$CLJS.Ob(a).name;var e=$CLJS.Ei(/^[^$]+\$[^$]+/,d);e=$CLJS.m(e)?AD.g?AD.g(e):AD.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.m(e)?e:d),"@",$CLJS.p.g($CLJS.Qa(a)),": "].join("");if($CLJS.m(gB()))$CLJS.Qc($CLJS.Sz,"#");else{c=fB;d=aB;fB+=1;aB=0;try{VA(b,"\x3e");pB(mB,-(b.length-2));lB(kB);var f=null!=a?a.K&1||$CLJS.t===a.ak?!0:a.K?!1:$CLJS.Nb(Mz,a):$CLJS.Nb(Mz,a);var g=f?!$CLJS.Eu(a):f;$CLJS.dB(g?pea:$CLJS.r(a));ZA()}finally{aB=d,fB=c}}return null});
Pz(FD,null,Rz);Pz(FD,$CLJS.Pj,YC);cB=BD;