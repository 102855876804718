var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.memoize.js");
'use strict';var J0=function(a,b){var c=$CLJS.He,d=I0;for(a=$CLJS.A(a);;)if(null!=a){d=$CLJS.M.j(d,$CLJS.C(a),c);if(c===d)return b;a=$CLJS.D(a)}else return d},K0=function(a){return $CLJS.oh.j($CLJS.oi,$CLJS.Pm.g(function(b){return $CLJS.xf(b)&&$CLJS.ki.h(b,$CLJS.sl)}),$CLJS.gi(a))},L0=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Io(function(b,c){$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.IG);return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)}),$CLJS.MP.g(a))},Toa=function(a){return $CLJS.eW(a,
function(b){return $CLJS.oh.j($CLJS.P,$CLJS.Pm.g(function(c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return $CLJS.xf(d)}),b)})},Uoa=new $CLJS.N("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769),Voa=new $CLJS.N("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439),M0=new $CLJS.N(null,"left","left",-399115937),N0=new $CLJS.N("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.O0=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.equality","\x3d"),function(f,g){f=$CLJS.CH(f);g=$CLJS.CH(g);return $CLJS.ki.h(f,g)?N0:f},$CLJS.Pj,e,a,b,c,d)}();$CLJS.O0.o(null,N0,function(){return!1});
$CLJS.O0.o(null,$CLJS.zG,function(a,b){var c=K0(a),d=K0(b);return $CLJS.F.h(c,d)&&$CLJS.Wf(function(e){return $CLJS.O0.h($CLJS.M.h(a,e),$CLJS.M.h(b,e))},c)});$CLJS.O0.o(null,$CLJS.xG,function(a,b){var c=$CLJS.F.h($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.A(a),$CLJS.C(c),$CLJS.D(c),c=$CLJS.A(b),$CLJS.C(c),$CLJS.D(c),c=a,a=b;;){c=$CLJS.A(c);b=$CLJS.C(c);var d=$CLJS.D(c);c=b;b=d;a=$CLJS.A(a);d=$CLJS.C(a);a=$CLJS.D(a);c=$CLJS.O0.h(c,d);if($CLJS.m(c)){if(c=$CLJS.je(b))return c;c=b}else return c}else return c});
var I0=null;$CLJS.O0.o(null,$CLJS.CZ,function(a,b){var c=I0;I0=new $CLJS.k(null,2,[M0,L0(a),$CLJS.PC,L0(b)],null);try{var d=$CLJS.tj($CLJS.O0,$CLJS.zG);return d.h?d.h(a,b):d.call(null,a,b)}finally{I0=c}});
$CLJS.O0.o(null,$CLJS.MP,function(a,b){var c=$CLJS.J(a,0,null),d=$CLJS.J(a,1,null),e=$CLJS.J(a,2,null),f=$CLJS.J(b,0,null),g=$CLJS.J(b,1,null),l=$CLJS.J(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.h(c,f))?(d=$CLJS.O0.h(d,g),$CLJS.m(d)?$CLJS.m(I0)?$CLJS.F.h(J0(new $CLJS.S(null,2,5,$CLJS.T,[M0,e],null),Voa),J0(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PC,l],null),Uoa)):$CLJS.F.h(e,l):d):c:a});
$CLJS.O0.o(null,$CLJS.Pj,function(a,b){if($CLJS.oe(a)){var c=$CLJS.tj($CLJS.O0,$CLJS.zG);return c.h?c.h(a,b):c.call(null,a,b)}return $CLJS.ne(a)?(c=$CLJS.tj($CLJS.O0,$CLJS.xG),c.h?c.h(a,b):c.call(null,a,b)):$CLJS.F.h(a,b)});var P0=function P0(a){switch(arguments.length){case 2:return P0.h(arguments[0],arguments[1]);case 3:return P0.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
P0.h=function(a,b){for(var c=$CLJS.Ye,d=new $CLJS.S(null,4,5,$CLJS.T,[function(){return function(g){return $CLJS.dW(g,$CLJS.q_,$CLJS.G([$CLJS.OR,$CLJS.Em,$CLJS.v0,$CLJS.sl]))}}(c),Toa,function(){return function(g){return $CLJS.dW(g,$CLJS.Em,$CLJS.G([$CLJS.DG,$CLJS.lk]))}}(c),function(){return function(g){return $CLJS.dW(g,$CLJS.Em,$CLJS.G([$CLJS.lQ]))}}(c)],null);;){var e=function(){var g=c.g?c.g(a):c.call(null,a);return $CLJS.vW(function(l,n,q){return function(u){return $CLJS.O0.h(l.g?l.g(u):l.call(null,
u),q)}}(c,d,g),b)}();if($CLJS.m(e))return e;if($CLJS.A(d)){e=$CLJS.Mm.h(c,$CLJS.C(d));var f=$CLJS.zd(d);c=e;d=f}else return null}};
P0.j=function(a,b,c){var d=P0.h(b,c);return $CLJS.m(d)?d:$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function l(f,g){try{if($CLJS.qe(g)&&3===$CLJS.E(g))try{var n=$CLJS.Td(g,0);if($CLJS.O(n,$CLJS.DQ))try{var q=$CLJS.Td(g,2);if($CLJS.Ae(q)){var u=$CLJS.Td(g,2),v=$CLJS.Td(g,1);n=$CLJS.T;var x=$CLJS.V.g($CLJS.s0(a,u));var y=$CLJS.m(x)?P0.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,v,x],null),c):null;return new $CLJS.S(null,1,5,n,[y],null)}throw $CLJS.Z;}catch(H){if(H instanceof Error){var B=H;if(B===$CLJS.Z)throw $CLJS.Z;
throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)return $CLJS.sZ(l,f,g);throw B;}throw H;}}($CLJS.Lg,b))))};P0.v=3;$CLJS.Q0=$CLJS.iG(P0,8);